<!-- TODO: Extract common logic between this and VoD Staging -->
<!-- Trigger change detection when on of these changes. -->
<ng-container *ngIf="{
  isEditing: stagingService.isEditing$ | async,
  selectedAssetSet: stagingService.selectedAssetSet$ | async,
  activeAssetSet: stagingService.activeItemSet$ | async
} as tplState">
    <ng-container *ngIf="assets &&
            tplState.isEditing != null &&
            tplState.selectedAssetSet &&
            tplState.activeAssetSet &&
            getSelectionInfo(assets, tplState.selectedAssetSet) as selectionInfo">
      <div class="actions-search-bar-container">
        <div class="action-bar-container">
          <div class="video-actions">
            <!-- (De)Select all toggle. -->
            <mat-checkbox color="primary" [disabled]="!assets.length || loading || tplState.isEditing" (change)="selectionInfo.areAll
                                  ? stagingService.select([])
                                  : stagingService.select(selectionInfo.selectableItems)"
              [checked]="selectionInfo.areAll" [indeterminate]="selectionInfo.indeterminate"
              [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
              [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)" matTooltipPosition="above"
              mam-firebase-ga="Toggle selection for all assets in vod errors">
            </mat-checkbox>

            <!-- Actions for selected assets. -->
            <ng-container *ngIf="selectionInfo.areAny">
              <!-- Edit -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button class="edit-selected main__icon--size"
                aria-label="Edit selected assets" matTooltip="Edit selected assets" matTooltipPosition="above"
                mam-firebase-ga="Bulk edit in vod errors" (click)="edit(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">edit</mat-icon>
              </button>
              <!-- Sync -->
              <button *featureFlagOn="'use-sync-metadata'" [disabled]="loading || tplState.isEditing" mat-icon-button
                class="sync-selected main__icon--size" aria-label="Sync selected assets" matTooltip="Sync selected assets"
                matTooltipPosition="above" mam-firebase-ga="Bulk sync in vod errors"
                (click)="syncMetadata(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">cloud_sync</mat-icon>
              </button>
              <!-- On-prem TTL Extension -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button
                class="extend-ttl-selected main__icon--size" aria-label="Extend TTL on selected assets"
                matTooltip="Extend TTL on selected assets" matTooltipPosition="above"
                mam-firebase-ga="Extend TTL on selected assets in vod errors"
                (click)="extendAssetsTtl(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">more_time</mat-icon>
              </button>
              <!-- On-prem purge -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button
                (click)="purgeAssets(selectionInfo.selectedItems)" class="purge-selected main__icon--size"
                aria-label="Delete on-prem files" matTooltip="Delete on-prem files" matTooltipPosition="above"
                mam-firebase-ga="Purge VoD errors selection">
                <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
              </button>
              <!-- Delete -->
              <button [disabled]="loading || tplState.isEditing" mat-icon-button class="delete-selected main__icon--size"
                aria-label="Delete selected assets" matTooltip="Delete selected assets" matTooltipPosition="above"
                mam-firebase-ga="Delete selected assets in vod errors"
                (click)="deleteAssets(selectionInfo.selectedItems)">
                <mat-icon class="material-icons-outlined">delete</mat-icon>
              </button>
            </ng-container>
          </div>

          <!-- Refresh -->
          <div class="video-actions">
            <button
              [disabled]="loading || tplState.isEditing"
              class="refresh-button main__icon--size"
              mat-icon-button
              aria-label="Refresh the list"
              matTooltip="Refresh the list"
              matTooltipPosition="above"
              mam-firebase-ga="Refresh table in vod errors"
              (click)="refreshTable()">
              <mat-icon>refresh</mat-icon>
            </button>
          </div>
        </div>

        <!-- Search & Pagination -->
        <div class="search-bar-container">
          <mat-form-field
            appearance="outline"
            class="search-bar"
    				subscriptSizing="dynamic"
            [class.disabled]="tplState.isEditing">
            <mat-icon matPrefix>
              search</mat-icon>
            <input
              type="text"
              [readonly]="tplState.isEditing"
              matInput
              [formControl]="search"
              placeholder="Search assets"
              aria-label="Search assets"
              autocomplete="off">
            <button
              [disabled]="tplState.isEditing"
              *ngIf="search.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              mam-firebase-ga="Clear asset search query in vod errors"
              (click)="search.setValue('')">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-paginator
            [disabled]="loading || tplState.isEditing"
            [length]="totalCount"
            [pageSize]="pageSize"
            [pageIndex]="currentPageIndex"
            (page)="changePage($event)"
            [pageSizeOptions]="PAGE_SIZE_OPTIONS"
            [hidePageSize]="!featureService.featureOn('use-vod-staging-page-size-selector')"
            class="vod-staging-paginator main__mat-paginator--border">
          </mat-paginator>
        </div>
      </div>

    <mam-ui-table
      [cols]="cols"
      [data]="assets"
      [options]="{
        tableInfo: {
            id: tableId,
            name: 'vodStagingErrorTable'
          },
          multi: true,
          mainColIdx: 1,
          loading: loading || false,
      }"
      [selectedRows]="tplState.selectedAssetSet"
      [activeRows]="tplState.activeAssetSet"
      (rowClick)="selectOrActivate($event, tplState.selectedAssetSet, $event.shiftKey)">
      <!-- table header -->

      <ng-template #headerTpl let-col>
        <ng-container *ngIf="col.headerTpl === 'sourceTpl'">
          <mam-sort-col-btn [rows]="assets" [key]="'source'" colName="Source" (mamSortByField)="onSortByField($event)">
            Source
          </mam-sort-col-btn>
        </ng-container>
      </ng-template>

      <!-- cells -->
      <ng-template #cellTpl let-row let-col="col">
        <!-- Checkbox -->
        <ng-container *ngIf="col.headerTpl === 'checkBoxTpl'">
          <mat-checkbox *ngIf="canBeSelected(row)" color="primary" [disabled]="loading || tplState.isEditing"
            (click)="$event.stopPropagation()" (change)="toggleSelection(row, tplState.selectedAssetSet)"
            [checked]="tplState.selectedAssetSet.has(row.name)"
            [aria-label]="tableUtils.checkboxLabel(row, tplState.selectedAssetSet)">
          </mat-checkbox>
        </ng-container>

        <!-- Title / File Name -->
        <ng-container *ngIf="col.cellTpl === 'nameTpl'">
          <div class="icon-with-text">
            <mam-asset-info-icon-button style="color: #3c4043;" [asset]="row" icon="error"
              [showIconForExternalUsers]="true" [tooltip]="formatError(row)">
            </mam-asset-info-icon-button>
            <span [title]="row.gcsLocationUrl">
              <span matTooltipClass="mobile-tooltip" matTooltip="{{assetService.getAssetTitle(row)}}"
                matTooltipPosition="below">{{assetService.getAssetTitle(row)}}</span>
            </span>
          </div>
        </ng-container>

        <!-- Duration -->
        <ng-container *ngIf="col.cellTpl === 'durationTpl'">
            {{row.duration | durationOrTime}}
        </ng-container>

        <!-- Schema Name -->
        <ng-container *ngIf="col.cellTpl === 'schemaTpl'">
          <span matTooltipClass="mobile-tooltip" matTooltip="{{assetService.getSchemaTitle(row) | async}}"
            matTooltipPosition="below">{{assetService.getSchemaTitle(row) | async}}
          </span>
        </ng-container>

        <!-- Approved -->
        <ng-container *ngIf="col.cellTpl === 'approvedTpl'"> {{row.approved ? 'Yes': 'No'}} </ng-container>

        <!-- Error -->
        <ng-container *ngIf="col.cellTpl === 'errorTpl'">
          <span matTooltipClass="mobile-tooltip" matTooltip="{{formatError(row)}}"
            matTooltipPosition="below">{{formatError(row)}}</span>
        </ng-container>

        <!-- Storage -->
        <ng-container *ngIf="col.cellTpl === 'storageTpl'">
          <div class="location-status-wrapper">
            <mam-asset-location-status *ngIf="!row.isDeleted" [asset]="row"> </mam-asset-location-status>
          </div>
        </ng-container>
      </ng-template>
    </mam-ui-table>

    <div *ngIf="assets.length === 0" class="empty-message">No assets found</div>
  </ng-container>
</ng-container>
