<h2 mat-dialog-title>This view is not longer available</h2>
<mat-dialog-content>
    <p>
        The view you are trying to access was deleted or is no longer available to you and the Default view will be
        selected.
    </p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]>Got it!</button>
</mat-dialog-actions>
