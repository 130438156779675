import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mam-ui-table-views-dialog',
  styleUrl: './ui-table-views-dialog.component.scss',
  templateUrl: './ui-table-views-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponeUiTableViewsDialogComponent implements OnInit{
  view: { id?:string, name?: string } = {};

  constructor(
    readonly dialogRef: MatDialogRef<ComponeUiTableViewsDialogComponent>,

    @Inject(MAT_DIALOG_DATA) public data: { id:string, name: string }) {

}
  ngOnInit(): void {
    if(this.data) {
      this.view = this.data;
    }
}
}
