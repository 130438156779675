import {BreakpointObserver} from '@angular/cdk/layout';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, signal} from '@angular/core';
import {FormControl} from '@angular/forms';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {combineLatest, ReplaySubject} from 'rxjs';
import {debounceTime, startWith, takeUntil} from 'rxjs/operators';

import { environment } from 'environments/environment';
import {TableCol} from 'ui/ui_table.type';

import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {TaskStatus, TransferRow, TransferRowFilter, TransferRowFilterChange, TransferRowSort, TransferService, TransferType} from '../services/transfer_service';

import {MultiSelectOption, MultiSelectOptions} from './multiselect_table_header';

/** Media breakpoints affecting the visibility of columns. */
export enum BreakPoint {
  COMPACT = '(max-width: 1280px)',
  HIDE_INTELLIGENCE = '(max-width: 960px)',
  HIDE_SIZE_AND_MODIFIED = '(max-width: 720px)',
}

const ALL_COLUMNS = [
  'name',
  'size',
  'source',
  'modifiedTime',
  'type',
  'intelligence',
  'status',
  'expand',
] as const;

/**
 * String literals union of all the possible column names.
 * - Refer to cl/344914657 to add a "select" (checkboxes) column.
 * - Refer to cl/345791367 to add an "origin" column.
 */
export type Column = typeof ALL_COLUMNS[number];

const ALWAYS_VISIBLE_COLUMNS: Column[] = ['name', 'modifiedTime', 'status', 'expand'];

const SEARCH_DEBOUNCE = 900;

/**
 * Table with pagination and search/filtering listing transfers. Parts of
 * the code come from MatTable official examples at
 * https://material.angular.io/components/table.
 */
@Component({
  selector: 'mam-transfer-table',
  templateUrl: './transfer_table.ng.html',
  styleUrls: ['./transfer_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransferTable implements OnInit, OnDestroy {
  cols: TableCol[] = [
    {
      key: 'name',
      name: 'Name',
      sorter: true,
      optioner: true,
      resizer: true,
      disabled: true,
      cellTpl: 'nameTpl',
      colStyle: {
        minWidth: '120px',
      },
      cellStyle: {
        padding: '0 12px 0 8px'
      },
      headerStyle: {
        width: '200px',
        minWidth: '120px',
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'size',
      name: 'Size',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      pipe: 'bytes',
      colStyle: {
        minWidth: '100px',
      },
      headerStyle: {
        width: '100px',
        minWidth: '100px',
      },
      order: 1,
      orderMenu: 1
    },
    {
      key: 'user',
      name: 'Source',
      optioner: true,
      dragger: true,
      resizer: true,
      headerTpl: 'userTpl',
      colStyle: {
        minWidth: '124px',
      },
      headerStyle: {
        width: '124px',
        minWidth: '124px',
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'modifiedTime',
      name: 'Last Modified',
      sorter: true,
      optioner: true,
      dragger: true,
      resizer: true,
      pipe: 'tzdate',
      pipeArg: 'MMM d, y, h:mm a',
      colStyle: {
        minWidth: '150px',
      },
      headerStyle: {
        width: '150px',
        minWidth: '150px'
      },
      cellStyle: {
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'type',
      name: 'Type',
      optioner: true,
      dragger: true,
      resizer: true,
      pipe: 'titlecase',
      headerTpl: 'typeTpl',
      cellTpl: 'typeTpl',
      colStyle: {
        minWidth: '100px'
      },
      headerStyle: {
        width: '100px',
        minWidth: '100px',
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'intelligence',
      name: 'Intelligence',
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'intelligenceTpl',
      colStyle: {
        minWidth: '90px',
      },
      headerStyle: {
        width: '90px',
        minWidth: '90px'
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'status',
      name: 'Status',
      optioner: true,
      dragger: true,
      resizer: true,
      headerTpl: 'statusTpl',
      cellTpl: 'statusTpl',
      colStyle: {
        minWidth: '116px'
      },
      headerStyle: {
        width: '116px',
        minWidth: '116px',
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'expand',
      name: '',
      stickyEnd: true,
      cellTpl: 'expandTpl',
      colStyle: {
        minWidth: '56px'
      },
      headerStyle: {
        width: '56px',
        minWidth: '56px'
      },
      cellStyle: {
        textAlign: 'center',
        // textOverflow: 'clip'
      },
      order: 7,
      orderMenu: 7
    }
  ];

  /** Individual items composing the table rows. */
  @Input()
  get rows(): TransferRow[] {
    return this.rowsInternal;
  }
  set rows(rows: TransferRow[]) {
    this.retriedRows.clear();
    this.rowsInternal = rows;
    this.rowsChanged$.next();
  }

  /** Current page index displayed in paginator element. */
  @Input() pageIndex = 0;

  /** Current page size displayed in paginator element. */
  @Input() pageSize = 0;

  /** Total number of items displayed in paginator element. */
  @Input() totalSize = 0;

  @Input()
  activeSort: TransferRowSort = {active: 'modifiedTime', direction: 'desc'};

  /** Text displayed when the input rows are empty. */
  @Input() emptyPlaceholder = 'No result.';

  /** Options for type filter. */
  typeFilterOptions: MultiSelectOptions<TransferType> = [
    {title: 'Any type', selected: true},
    {title: 'Download', value: TransferType.DOWNLOAD},
    {title: 'Upload', value: TransferType.UPLOAD}
  ];

  @Input()
  get activeFilter(): TransferRowFilter {
    return this.filterInternal;
  }
  set activeFilter(filter: TransferRowFilter) {
    this.filterInternal = filter;
    this.typeFilterOptions = this.typeFilterOptions.map(opt => {
      opt.selected = opt.value === filter.type;
      return opt;
    });
    this.statusFilterOptions = this.statusFilterOptions.map(opt => {
      opt.selected = opt.value === filter.status;
      return opt;
    });
  }

  /** Options for status filter. */
  statusFilterOptions: MultiSelectOptions<TaskStatus> = [
    {title: 'Any Status', selected: true},
    {title: 'Active', value: TaskStatus.ACTIVE},
    {title: 'Failed', value: TaskStatus.FAILED},
    {title: 'Completed', value: TaskStatus.COMPLETED},
  ];

  /** Emits when navigating to a new page is requested. */
  @Output() readonly page = new EventEmitter<PageEvent>();

  /** Emits when clicking on a failed task's "Retry" button. */
  @Output() readonly retry = new EventEmitter<string>();

  /** Emits when sorting is requested. */
  @Output() readonly sort = new EventEmitter<Sort>();

  /** Emits when filter is changed. */
  @Output() readonly filter = new EventEmitter<TransferRowFilterChange>();

  /** List of columns to render in the table. */
  displayedColumns = signal<Column[]>([]);

  /** Form control for search input. */
  search = new FormControl<string>('');

  /** Expose status enumeration to template. */
  readonly TaskStatus = TaskStatus;

  tableId:string;

  constructor(
      readonly transferService: TransferService,
      private readonly breakpoint: BreakpointObserver,
      private readonly cdr: ChangeDetectorRef,
      private readonly featureFlag: FeatureFlagService
  ) {
    this.tableId = environment.tableInfoId['transferTable'];

    this.search.valueChanges
        .pipe(
            debounceTime(SEARCH_DEBOUNCE),
            startWith(''),
            takeUntil(this.destroyed),
            )
        .subscribe(value => {
          this.filter.emit({type: 'name', value} as TransferRowFilterChange);
        });
  }

  ngOnInit() {
    // Adjust visible columns when window size changes, or when new rows are
    // passed and the `hideColumns` property is not empty.
    this.startResponsiveLayout();
  }

  isRowError(row: TransferRow) {
    return row.status === TaskStatus.FAILED;
  }

  isRowExpanded(row: TransferRow) {
    return this.expandedRows.has(row.id);
  }

  isUploadTask(row: TransferRow) {
    return row.type === TransferType.UPLOAD;
  }

  isRowRetried(row: TransferRow) {
    return this.retriedRows.has(row.id);
  }

  onRetryClicked(row: TransferRow) {
    // Prevent to click again on "Retry" until input data is updated.
    this.retriedRows.add(row.id);
    this.retry.emit(row.id);
  }

  toggleRowExpanded(row: TransferRow) {
    if (this.expandedRows.has(row.id)) {
      this.expandedRows.delete(row.id);
    } else {
      this.expandedRows.add(row.id);
    }
  }

  onTypeFilterChanged(selected: MultiSelectOption) {
    this.filter.emit({type: 'type', value: selected.value as TransferType});
  }

  onStatusFilterChanged(selected: MultiSelectOption) {
    this.filter.emit({type: 'status', value: selected.value as TaskStatus});
  }

  goToPage(event: PageEvent) {
    // Rows open on the current page should be collapsed when we come back to
    // this page.
    this.expandedRows.clear();
    this.page.emit(event);
  }

  trackByRow(index: number, row: TransferRow) {
    return row.id;
  }

  private rowsInternal: TransferRow[] = [];

  /** List of rows that we clicked "Retry" on, which disable the button. */
  private readonly retriedRows = new Set<string>();

  /** List of rows that have been expanded to reveal their details. */
  readonly expandedRows = new Set<string>();

  private readonly destroyed = new ReplaySubject<void>(1);

  private filterInternal: TransferRowFilter = {};

  /** Emits when the `rows` input changes. */
  private readonly rowsChanged$ = new ReplaySubject<void>(1);

  /**
   * Observes screen size breakpoints and limit the number of visible columns
   * accordingly. This is also updated when new data is received, since
   * different columns may be shown in different tabs. We do not refresh visible
   * columns as soon as the`hiddenColumns` input changes as the new set of
   * columns may not be compatible with the current rows.
   */
  private startResponsiveLayout() {
    combineLatest([
      this.breakpoint.observe(Object.values(BreakPoint)),
      this.rowsChanged$,
    ])
        .pipe(takeUntil(this.destroyed))
        .subscribe(([result]) => {
          // Initial set of always visible columns.
          const visible = new Set<Column>(ALWAYS_VISIBLE_COLUMNS);

          if (!result.breakpoints[BreakPoint.HIDE_SIZE_AND_MODIFIED]) {
            visible.add('size');
            visible.add('type');
            if (this.featureFlag.featureOn('show-user-information')) {
              visible.add('source');
            }
          }

          if (!result.breakpoints[BreakPoint.HIDE_INTELLIGENCE]) {
            visible.add('intelligence');
          }

          const displayedColumns = ALL_COLUMNS.filter(c => visible.has(c));
          this.displayedColumns.set(displayedColumns);

          this.cdr.markForCheck();
        });
  }

  onResetCols() {
    this.startResponsiveLayout();
  }

  onSortByField(rows: TransferRow[]) {
    this.rows = rows;
    this.cdr.detectChanges();
  }

  onSort(sort: Sort) {
    this.sort.emit(sort);
  }

  onRowClick(row: TransferRow) {
    this.toggleRowExpanded(row);
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
