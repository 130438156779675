import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, InjectionToken, Input, OnDestroy, ViewChild} from '@angular/core';
import {DocumentReference} from '@firebase/firestore';
import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, firstValueFrom, merge, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {delay, filter, map, shareReplay, switchMap, take, takeUntil, tap, throttleTime, withLatestFrom} from 'rxjs/operators';

import {AuthService} from '../../../auth/auth_service';
import { environment } from '../../../environments/environment';
import {ErrorResponse, isErrorResponse} from '../../../error_service/error_response';
import {NEVER_RETRY_STATUS_CODES} from '../../../error_service/error_service';
import {StatusCode} from '../../../error_service/status_code';
import {FeatureFlagService} from '../../../feature_flag/feature_flag_service';
import {AnalyticsEventType, FirebaseAnalyticsService, PAGE_CONTEXT_TOKEN} from '../../../firebase/firebase_analytics_service';
import {FirebaseFirestoreDataService, IASEvent} from '../../../firebase/firebase_firestore_data_service';
import {FirestoreIASEventHelper} from '../../../firebase/firebase_firestore_ias_event_helper';
import {PublishRetryTransferService} from '../../../internal_pubsub/publish_retry_transfer_service';
import {PluginService} from '../../../plugin/plugin_service';
import {HostImportAction, HostOtherAction} from '../../../plugin/plugin_types';
import {Asset, AssetService, Clip} from '../../../services/asset_service';
import {BinService} from '../../../services/bin.service';
import {IntersectionObserverService} from '../../../services/intersection_observer_service';
import {FileAndState, FileState, ImportInput, MediaCacheService} from '../../../services/media_cache_service';
import {FILE_STATE_INTERVAL, Path} from '../../../services/media_cache_state_service';
import {SnackBarService} from '../../../services/snackbar_service';

/** Delay for requesting location status. */
export const STATUS_REQUEST_DELAY_MS = 500;

// 4s should be more than enough to check presence of a single clip.
const CHECK_ASSET_PRESENCE_TIMEOUT_MS = 4000;

/**
 * We are seeing large volume of assetCheck timeouts in prod and are able to
 * reproduce them on large clip bins (~ 150 clips). Looks like the plugin queues
 * all incoming requests and processes them one by one. Sending large volume of
 * requests, like we currently do, clogs up communication channel and leads to
 * more timeouts and blocks more useful messages like asset import from going
 * through.
 *
 * Currently, we send min(`binSize`, 24) requests every 4 seconds, scrolling down
 * the clipbin would fetch more assets and eventually lead to sending `binSize`
 * requests every 4 seconds. Timeouts are still happening for large clipbins
 * even after raising the timeout to 30 seconds. Additionally, the more files
 * are present in the Premiere's bin the more time it takes to loop through them
 * and get their metadata when checking if the asset exists.
 *
 * Potential improvements:
 * - Batching assetCheck requests and optimizing plugin logic for bulk checks
 * inside a single bin.
 * - Checking if component is visible before making the check
 * - Moving away from periodic requests
 *
 * While we explore our options we disable the asset check altogether to make
 * sure that import request go through unobstructed.
 */
export const DISABLE_ASSET_CHECK = new InjectionToken<boolean>(
    'Disable asset presence check', {factory: () => true});

/**
 * Shows asset cloud/onprem status. Provides download action. Provides import
 * action when running in IAS plugin.
 */
@Component({
  selector: 'mam-asset-location-status',
  templateUrl: './asset_location_status.ng.html',
  styleUrls: ['./asset_location_status.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetLocationStatus implements OnDestroy, AfterViewInit {
  readonly asset$ = new ReplaySubject<Asset>(1);
  @ViewChild('wrapper') readonly wrapperEl!: ElementRef<HTMLElement>;

  @Input()
  set asset(asset: Asset) {
    this.asset$.next(asset);
  }

  /**
   * Present location status for the raw source of the asset.
   */
  @Input() checkRawSource = false;

  @Input() onpremOperation = false;

  @Input() displayLocationText = false;

  /**
   * Display title of the clipbin which will create a Premiere folder of the
   * same name when its clip is imported. If not provided, it will be fetched
   * before the import.
   */
  @Input() binTitle = '';

  readonly FileState = FileState;

  readonly PAGE_CONTEXT_TOKEN = PAGE_CONTEXT_TOKEN;

  assetKey = '';

  readonly fileAndState$: Observable<FileAndState>;

  readonly tooltip$ = this.mediaCache.state.filePath$.pipe(map(path => {
    return 'Download from cloud to on-prem at ' + path.siteId;
  }));

  /**
   * Indicates that the status is invalid and needs to be hidden until new
   * status is fetched.
   */
  readonly hideStatus$ = new BehaviorSubject(true);

  /**
   * The asset's on-prem file TTL. It can be undefined if Media Cache doesn't
   * provide the info.
   */
  ttl? = '';

  /** Refer to if the on-prem file TTL is less than 24 hours from now. */
  isExpiring = false;

  /** Whether the current asset is already imported into Premiere. */
  isInPremiere = false;

  constructor(
      readonly pluginService: PluginService,
      readonly assetService: AssetService,
      private readonly binService: BinService,
      private readonly authService: AuthService,
      private readonly mediaCache: MediaCacheService,
      private readonly snackbar: SnackBarService,
      private readonly analyticsService: FirebaseAnalyticsService,
      private readonly dataService: FirebaseFirestoreDataService,
      private readonly intersectionObserver: IntersectionObserverService,
      private readonly cdr: ChangeDetectorRef,
      private readonly featureService: FeatureFlagService,
      private readonly iasEventHelper: FirestoreIASEventHelper,
      private readonly publishRetryTransferService: PublishRetryTransferService,
      @Inject(DISABLE_ASSET_CHECK) private readonly disableAssetCheck: boolean,
  ) {
    // When the site or asset are changed, make sure that the component will
    // update state immediately if/when becomes visible.
    merge(this.asset$, this.mediaCache.state.filePath$)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.isInPremiere = false;
          this.lastUpdateTriggeredTime = 0;
          this.isFatalError = false;
          // Hide state when the site changes until new data is fetched.
          this.hideStatus$.next(true);
        });

    this.fileAndState$ = this.watchFileAndState();

    combineLatest([this.fileAndState$, this.asset$])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(([fileAndState]) => {
          this.updateTtlStatus(fileAndState);
          // Display status once updated file state is fetched.
          this.hideStatus$.next(false);
        });

    this.watchAssetPresence();
  }

  ngAfterViewInit() {
    this.intersectionObserver.observe(this.wrapperEl.nativeElement)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(visible => this.componentVisible$.next(visible));
  }

  async download() {
    const asset = await firstValueFrom(this.asset$);
    const path = await firstValueFrom(this.mediaCache.state.filePath$);

    this.mediaCache
        .downloadAsset(asset, path.siteId, path.folderId, this.checkRawSource)
        .subscribe(response => {
          // Refresh the status regardless of API result.
          this.manualStateUpdate$.next(undefined);

          // Treat it as success when `Requested entity already exist`.
          if (isErrorResponse(response) &&
              response.status !== StatusCode.CONFLICT) {
            this.snackbar.error({
              message: `Failed to queue download of ${asset.title}`,
              details: response.message,
              doNotLog: true,
            });
          } else {
            if (this.featureService.featureOn('store-user-information')) {
              this.storeIASEvent(asset,path);
            }
            this.snackbar.message(`Download of ${asset.title} has been queued`);
          }
        });
  }

  private storeIASEvent(asset: Asset, path: Path) {
    this.assetService.getClip(asset.name).subscribe(clipValue => {
      if (!clipValue) return;

      const clip = (clipValue as Clip);
      const iasEvent : IASEvent = this.iasEventHelper.formatTransferIASEvent(clip,asset.title, path);

      let docId: string = '';

      this.dataService.createIASEvent(iasEvent).then(async docRef => {
        if (docRef && !iasEvent.filename) {
          const document = docRef as DocumentReference;
          docId = document.id;
          const token = await this.authService.getActiveAccessToken();
          const folderKey = `${environment.mamApi.parent}/sites/${path.siteId}/folders/${path.folderId}`;
          this.queueGetAssetTransferInfo(asset, folderKey, token, docId);
        }
        return docRef;
      });

      this.analyticsService.logEvent('Call Download Clip Function from api', {
        term: asset.name,
        eventType: AnalyticsEventType.API_CALL,
        string1:  iasEvent.filename,
        string2: iasEvent.state
      });
    });
  }

  private queueGetAssetTransferInfo(asset: Asset, path: string,  token: string, docId : string) {
    this.publishRetryTransferService.publishRetryTransferMessage(asset.name, asset.title, path,token,docId).subscribe();
  }

  /**
   * Imports an asset/clip from MAM to Premiere Pro.
   */
  async import(asset: Asset, fileState: FileState|ErrorResponse) {
    if (isErrorResponse(fileState)) {
      this.snackbar.error({
        message: `${asset.original ? 'Clip' : 'Asset'} cannot be imported`,
        details: fileState.message,
      });
      return;
    }

    const localPath = await firstValueFrom(this.mediaCache.locateOnPrem(
        asset, fileState, {checkRawSource: this.checkRawSource}));

    if (isErrorResponse(localPath)) {
      this.snackbar.error({
        message: `${asset.original ? 'Clip' : 'Asset'} cannot be located`,
        details: localPath.message,
      });
      return;
    }

    let binTitle = this.binTitle;

    if (asset.original && !binTitle) {
      const bin = await firstValueFrom(this.binService.getBin(asset.label));
      if (isErrorResponse(bin)) {
        this.snackbar.error({
          message: `Failed to fetch clipbin before import`,
          details: bin.message,
        });
        return;
      }
      binTitle = bin.title;
    }

    const importInput: ImportInput = {
      asset,
      binTitle,
      fileState,
      localPath,
    };

    const response = this.mediaCache.importAsset(importInput);

    const error = isErrorResponse(response) ? response : undefined;
    this.analyticsService.logEvent(`Premiere import asset`, {
      eventType: AnalyticsEventType.PLUGIN_ACTION,
      resource: asset.name,
      string1: error?.message ?? '',
      boolean1: !error,
      object: error ? undefined : JSON.stringify(response),
    });

    if (isErrorResponse(response)) {
      this.snackbar.error({
        message: `${asset.original ? 'Clip' : 'Asset'} import failed`,
        details: response.message,
      });
      return;
    }

    this.afterImport$.next(undefined);
  }

  getErrorMessage(response: FileState|ErrorResponse) {
    const defaultMessage = 'Invalid file state';
    if (isErrorResponse(response) && response.message) {
      if (response.status && [
            StatusCode.BAD_REQUEST,
            StatusCode.PRECONDITION_FAILED,
          ].includes(response.status)) {
        return 'Missing file in Cloud Storage';
      }

      return response.message;
    }

    return defaultMessage;
  }

  formatFileLocation(fileState?: FileState|ErrorResponse, asset?: Asset) {
    if (!fileState || !asset) return '';

    if (isErrorResponse(fileState)) {
      return this.getErrorMessage(fileState);
    }

    return this.mediaCache.formatFileLocation(fileState);
  }

  /**
   * If import is available, returns whether direct file import or subclippping
   * will be executed. Returns `null` if import is not available.
   */
  getImportType(asset: Asset, fileState: FileState|ErrorResponse):
      HostImportAction|null {
    return this.mediaCache.getImportType(asset, fileState);
  }

  displayOnpremOperation(fileState: FileState|ErrorResponse) {
    if (isErrorResponse(fileState)) return false;

    return this.onpremOperation && [
      FileState.FILE_CLOUD_AND_ONPREM,
      FileState.FILE_ONPREM_ONLY,
    ].includes(fileState);
  }

  getImportTooltip(fileState: FileState|ErrorResponse) {
    if (isErrorResponse(fileState)) return '';

    if ([
          FileState.SUBCLIP_CLOUD_AND_ONPREM,
          FileState.SUBCLIP_ONPREM_ONLY,
        ].includes(fileState)) {
      return 'Import clip to Premiere';
    }

    return 'Import asset to Premiere';
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private readonly afterImport$ = new Subject<void>();
  private readonly destroyed$ = new ReplaySubject<void>(1);

  private readonly componentVisible$ = new BehaviorSubject<boolean>(false);

  /**
   * Timestamp of the latest trigger for file state updates. Initial value is
   * set to `0` to allow for immediate update.
   */
  private lastUpdateTriggeredTime = 0;

  /**
   * Emits when an immediate update of the status should be fetched. It happens
   * after a download is initiated because the new file state will show as a
   * spinner, and we don't need to wait for the next periodic refresh.
   */
  private readonly manualStateUpdate$ = new BehaviorSubject(undefined);

  /**
   * Whether the current file state is an error that should not be periodically
   * re-tried until the asset or current site is changed.
   */
  private isFatalError = false;

  /**
   * Creates an observable that emits periodically when the component is visible
   * for at least `STATUS_REQUEST_DELAY_MS` (500ms) and one of the following
   * happens:
   * - this.mediaCache.state.filePath$ emits (site has changed)
   * - this.asset$ emits (asset has changed)
   * - mediaCacheUpdate$ emits and at least `FILE_STATE_INTERVAL` seconds is
   * passed since last update. This ensures that frequently showing/hiding the
   * component (e.g. via scroll up and down) does not trigger updates.
   */
  private setupPeriodicUpdateRequests(): Observable<LocationRequest> {
    // Delay api request to ignore brief appearance of the component. E.g. when
    // scrolling through the list of items containing location status component.
    const componentVisibleDelayed$ =
        this.componentVisible$.pipe(switchMap(enable => {
          if (!enable) return of(false);
          return of(true).pipe(delay(STATUS_REQUEST_DELAY_MS));
        }));

    return combineLatest([
             componentVisibleDelayed$,
             this.mediaCache.state.mediaCacheUpdate$,
             this.asset$,
           ])
        .pipe(
            // Ignore periodic requests if the current state is a fatal error.
            filter(() => !this.isFatalError),
            // Skip this update if the component is hidden
            filter(([updatesEnabled]) => updatesEnabled),
            // Skip this update if the previous one was done recently
            filter(() => {
              return Date.now() - this.lastUpdateTriggeredTime >=
                  FILE_STATE_INTERVAL;
            }),
            map(([, filePath, asset]) => {
              return {asset, path: filePath};
            }),
            tap(() => {
              this.lastUpdateTriggeredTime = Date.now();
            }),
        );
  }

  /**
   * Watching the file state when there's any path updates from mediaCache or
   * when the current asset changes. The file can be a live clip, VoD clip, or a
   * completed VoD. If the file is a clip, giving the clip asset to receive its
   * PFR state.
   */
  private watchFileAndState(): Observable<FileAndState> {
    const periodicRequests$: Observable<LocationRequest> =
        this.setupPeriodicUpdateRequests();

    // Manual requests are either from this asset location (clicking on its
    // download button) of from a batch download. We re-fetch the clip as its
    // PFR info state may have changed.
    const manualRequests$: Observable<LocationRequest> =
        merge(this.manualStateUpdate$, this.mediaCache.state.manualUpdate$)
            .pipe(
                withLatestFrom(this.asset$),
                withLatestFrom(this.mediaCache.state.filePath$),
                map(([[, asset], path]) => ({asset, path})),
            );

    // Combine periodic update requests (when the component is visible, and when
    // the asset/file changes or at a slow pace periodically) with manual
    // refresh requests when the user downloads this asset.
    const updatedRequest$ = merge(periodicRequests$, manualRequests$);

    return updatedRequest$.pipe(
        switchMap(request => {
          if (isErrorResponse(request)) return of({state: request});
          const {path, asset} = request;
          return this.mediaCache.getFileAndState(
              path.siteId, path.folderId, asset,
              {checkRawSource: this.checkRawSource});
        }),
        // If the call failed and should not be re-tried, disable further
        // file state checks until the asset or site is changed.
        tap(response => {
          if (isErrorResponse(response.state) &&
              response.state.status != null && [
                StatusCode.NOT_FOUND,
                ...NEVER_RETRY_STATUS_CODES,
              ].includes(response.state.status)) {
            this.isFatalError = true;
          }
        }),
        // Multicast the fileState result to all the subscribers.
        shareReplay({bufferSize: 1, refCount: true}),
    );
  }

  /**
   * Ask Premiere Pro to check if the asset is present in the project.
   */
  private watchAssetPresence() {
    if (this.disableAssetCheck) return;

    if (!this.authService.isPlugin()) return;

    // Asset presence check is now only supported from v3.3.1
    if (!this.pluginService.isVersionAtLeast('3.3.1')) return;

    // Check periodically on mediaCache updates (which include site change or
    // manual updates triggered after a bulk import), as well as right after
    // this asset has been individually imported.
    merge(this.mediaCache.state.mediaCacheUpdate$, this.afterImport$)
        .pipe(
            // Prevent concurrent checks of the same asset
            throttleTime(
                CHECK_ASSET_PRESENCE_TIMEOUT_MS, undefined,
                {leading: true, trailing: true}),
            switchMap(() => this.asset$.pipe(take(1))),
            switchMap((asset) => {
              return this.pluginService.request(
                  {
                    iasType: HostOtherAction.CHECK_ASSETS,
                    payload: [{
                      binTitle: this.binTitle,
                      assetUrl: this.mediaCache.getAssetUrl(asset),
                    }],
                  },
                  CHECK_ASSET_PRESENCE_TIMEOUT_MS);
            }),
            map(response => {
              // Ignore errors and consider that the asset is not there.
              return isErrorResponse(response) ? false : response.payload[0];
            }),
            takeUntil(this.destroyed$),
            )
        .subscribe(isInPremiere => {
          this.cdr.markForCheck();
          this.isInPremiere = isInPremiere;
        });
  }

  private updateTtlStatus(fileAndState: FileAndState) {
    const {onpremFile, state} = fileAndState;
    this.ttl = undefined;
    this.isExpiring = false;
    if (state === FileState.FILE_CLOUD_AND_ONPREM &&
        !isErrorResponse(onpremFile)) {
      this.ttl = onpremFile?.lifecycleInfo.scheduleTime;
      if (this.ttl) {
        this.isExpiring = DateTime.fromISO(this.ttl).diffNow().as('days') < 1;
      }
    }
  }
}

interface LocationRequest {
  asset: Asset;
  path: Path;
}
