import { Injectable, NgZone } from '@angular/core';
import { deleteDoc, deleteField, doc, Firestore, getDoc, QueryFieldFilterConstraint, QueryFilterConstraint, Timestamp, updateDoc, where } from '@firebase/firestore';
import { map, Observable } from 'rxjs';

import { IasViewsSharing, IasViewTable, IasViewUser } from 'ui/ui_table_view.interface';

import { FirebaseResolver } from './firebase_resolver';

const collectionIASViews = 'ias-views';
const collectionsViewsUsers = 'ias-views-users';
const collectionViewsShared = 'ias-views-shared';

@Injectable({ providedIn: 'root' })
export class FirebaseViewsService {
  constructor(
    private readonly ngZone: NgZone,
    private readonly firebaseResolver: FirebaseResolver
  ) {}

  /**
   * Creates or updates a document in the 'ias-views-users' Firestore collection with the
   * specified id and data. Async
   *
   * @param id - The ID of the document to be created or updated. {string}
   * @param data - The data to be written to the document. {IasViewUser}
   * @returns A promise that resolves when the operation is complete.
   */
  async setDocumentViewsUser(id: string, data: IasViewUser): Promise<void> {
    return this.ngZone.runOutsideAngular(() => {
      return this.firebaseResolver.addOrUpdateFirestoreDoc(collectionsViewsUsers, id, data);
    });
  }

  /**
   * Creates or updates a document in the 'ias-views' Firestore collection with the
   * specified id and data. Async
   *
   * @param id - The ID of the document to be created or updated. {string}
   * @param data - The data to be written to the document. {IasViewTable}
   * @returns A promise that resolves when the operation is complete.
   */
  async setDocumentViews(id: string, data: IasViewTable): Promise<void> {
    data.createdAt = Timestamp.now();
    return this.ngZone.runOutsideAngular(() => {
      return this.firebaseResolver.addOrUpdateFirestoreDoc(collectionIASViews, id, data);
    });
  }

  /**
   * Creates or updates a document in the 'ias-views-shared' Firestore collection with the
   * specified id and data. Async
   *
   * @param id - The ID of the document to be created or updated. {string}
   * @param data - The data to be written to the document. {IasViewsSharing}
   * @returns A promise that resolves when the operation is complete.
   */
  async setDocumentSharedView(id: string, data: IasViewsSharing): Promise<void> {
    data.createdAt = Timestamp.now();
    return this.ngZone.runOutsideAngular(() => {
      return this.firebaseResolver.addOrUpdateFirestoreDoc(collectionViewsShared, id, data);
    });
  }

  /**
   * Retrieves a document from 'ias-views-users' Firestore collection by the specified id. Async
   *
   * @param id - The ID of the document to be retrieved. {string}
   * @returns A promise that resolves to the IasViewUser object associated with the specified id.
   */
  async getViewsUserById(id: string) {
    const ref = doc(this.getDB(), collectionsViewsUsers, id);
    return await getDoc(ref);
  }

  /**
   * Retrieves a document from 'ias-views' Firestore collection by the specified id. Async
   *
   * @param id - The ID of the document to be retrieved. {string}
   * @returns A promise that resolves to the IasViewTable object associated with the specified id.
   */
  async getViewById(id: string) {
    const ref = doc(this.getDB(), collectionIASViews, id);
    return await getDoc(ref);
  }

  /**
   * Retrieves a shared view document from 'ias-views-shared' Firestore collection by the specified id. Async
   *
   * @param id - The ID of the document to be retrieved. {string}
   * @returns A promise that resolves to the IasViewsSharing object associated with the specified id.
   */
  async getSharedViewById(id: string): Promise<IasViewsSharing> {
    const ref = doc(this.getDB(), collectionViewsShared, id);
    return (await getDoc(ref)).data() as IasViewsSharing;
  }

  /**
   * Deletes a document from 'ias-views' Firestore collection by the specified id. Async
   *
   * @param id - The ID of the document to be deleted. {string}
   * @returns A promise that resolves when the document is deleted.
   */
  async deleteViewDocById(id: string): Promise<void> {
    const ref = doc(this.getDB(), collectionIASViews, id);
    await deleteDoc(ref);
  }

  /**
   * Deletes a specific user's field from the 'ias-views-users' Firestore collection. Async
   *
   * @param userId - The ID of the user whose field is to be deleted. {string}
   * @param field - The field to be deleted. {string}
   * @returns A promise that resolves when the field is successfully deleted.
   */
  async deleteFieldFromViewsUser(userId: string, field: string): Promise<void> {
    const ref = doc(this.getDB(), collectionsViewsUsers, userId);
    await updateDoc(ref, {
      [field]: deleteField()
    });
  }

  /**
   * Updates a specific user's field in the 'ias-views-users' Firestore collection. Async
   *
   * @param userId - The ID of the user whose field is to be updated. {string}
   * @param field - The field to be updated. {string}
   * @param value - The new value of the field. {T}
   * @returns A promise that resolves when the field is successfully updated.
   */
  async updateFieldFromViewUser<T = string>(userId: string, field: string, value: T): Promise<void> {
    const ref = doc(this.getDB(), collectionsViewsUsers, userId);
    await updateDoc(ref, {
      [field]: value
    });
  }

  /**
   * Updates a specific field from a specific view in the 'ias-views' Firestore collection. Async
   *
   * @param viewId - The ID of the view whose field is to be updated. {string}
   * @param field - The field to be updated. {string}
   * @param value - The new value of the field. {T}
   * @returns A promise that resolves when the field is successfully updated.
   */
  async updateViewField<T = string>(viewId: string, field: string, value: T): Promise<void> {
    const ref = doc(this.getDB(), collectionIASViews, viewId);
    await updateDoc(ref, {
      [field]: value
    });
  }

  /**
   * Deactivate a specific view in the 'ias-views-shared' Firestore collection.
   * Set the 'isActive' field to false and the 'deletedAt' field to the current timestamp. Async
   *
   * @param viewId - The ID of the view to be deactivated. {string}
   * @returns A promise that resolves when the view is successfully deactivated.
   */
  async deactivateSharedView(viewId: string): Promise<void> {
    const ref = doc(this.getDB(), collectionViewsShared, viewId);
    await updateDoc(ref, {
      'isActive': false,
      'deletedAt': Timestamp.now()
    });
  }

  /**
   * Update a view name to specific view.
   *
   * @param viewId The ID of the view to be update
   * @param name The name to be update
   */

  async updateNameSharedView(viewId: string, name: string): Promise<void> {
    const constraints: QueryFieldFilterConstraint[] = [
      where('viewId', '==', viewId),
      where('isActive', '==', true),
    ];

    const docExist = await this.firebaseResolver.documentExist(collectionViewsShared, constraints);

    if (docExist) {
      const ref = doc(this.getDB(), collectionViewsShared, viewId);
      await updateDoc(ref, {
        'viewName': name,
        'updatedAt': Timestamp.now()
      });
    }
  }

  /**
   * Retrieves shared views associated with a user and table ID from the 'ias-views-shared' Firestore collection.
   *
   * @param email - The email of the user whose shared views are to be retrieved. {string}
   * @param tableId - The ID of the table associated with the shared views. {string}
   * @returns An observable that emits an array of IasViewsSharing objects.
   */
  getViewSharedWithUser(email: string, tableId: string): Observable<IasViewsSharing[]> {
    const constraints: QueryFieldFilterConstraint[] = [
      where('sharedWith', 'array-contains', email),
      where('tableId', '==', tableId),
      where('isActive', '==', true),
    ];

    return this.queryIASViewShared(constraints);
  }

  // Core Firebase Methods

  private queryIASViewShared(constraints: QueryFilterConstraint[]): Observable<IasViewsSharing[]> {
    return this.queryDocument(collectionViewsShared, constraints)
      .pipe(
        map(documents =>
          documents.map(document => {
            return { ...document.data() } as IasViewsSharing;
          }))
      );
  }

  private queryDocument(collection: string, constraints: QueryFilterConstraint[]) {
    return this.firebaseResolver.queryCollectionWithoutLimitSize(collection, constraints);
  }


  private getDB(): Firestore {
    return this.firebaseResolver.getFirestore() as Firestore;
  }
}
