import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { BehaviorSubject, firstValueFrom, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, finalize, map, shareReplay, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { assumeExhaustiveAllowing } from 'asserts/asserts';
import { environment } from 'environments/environment';
import { FeatureFlagService } from 'feature_flag/feature_flag_service';
import { Site } from 'models';
import { Pagination, PaginationService } from 'services/pagination_service';
import { TableCol } from 'ui/ui_table.type';


import { AnalyticsEventType, FirebaseAnalyticsService } from '../firebase/firebase_analytics_service';
import { ActiveItems, StagingService, StagingView } from '../right_panel/staging_service';
import { AssetState, MetadataField } from '../services/asset_api_service';
import { AssetCopy, AssetRowFilterChange, AssetRowSort, AssetService, ListResponse, Original } from '../services/asset_service';
import { MediaCacheService } from '../services/media_cache_service';
import { ProgressbarService } from '../services/progressbar_service';
import { SnackBarService } from '../services/snackbar_service';
import { StateService } from '../services/state_service';
import { TableUtils } from '../services/table_utils';
import { TimezoneService } from '../services/timezone_service';

import { StagingTable } from './staging_table_base';

const enum LiveAssetIcon {
	SPINNER = 'spinner',
	APPROVED = 'check',
	ERROR = 'error',
	DEFAULT = 'youtube_live'
}

/** All available columns for live staging table. */
const ALL_COLUMNS = [
	'select',
	'title',
	'source',
	'type',
	'description',
	'sport',
	'start',
	'end',
  'duration',
	'camera',
	'courtesy',
	'cutdown',
	'expand'
] as const;

type Column = typeof ALL_COLUMNS[number];

/** Provides metadata field name based on table column name. */
const COLUMN_TO_METADATA_KEY = {
	camera: MetadataField.CAMERA_ANGLE_TYPE,
	courtesy: MetadataField.COURTESY,
	description: MetadataField.DESCRIPTION,
	sport: MetadataField.SPORT,
	type: MetadataField.CONTENT_TYPE,
	title: MetadataField.TITLE,
	source: MetadataField.SITE
} as const;

const DEFAULT_SORT: Sort = {
	active: 'title',
	direction: 'asc'
};

/** Url param name for selected date in Live Staging. */
export const LIVE_STAGING_DATE_PARAM = 'lsDate';

/** 'All sites' option (for the site filter). */
export const ALL_SITES_OPTION = { siteId: 'All Sites' } as Site;

/**
 * Live content staging table.
 */
@Component({
	selector: 'mam-live-staging-table',
	templateUrl: './live_staging_table.ng.html',
	styleUrls: ['./live_staging_table.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveStagingTable extends StagingTable implements OnInit {

	/** Flag to show assets' source column. */
	readonly showAssetsSource = this.featureService.featureOn('show-user-information');

	cols: TableCol[] = [
		{
			key: 'select',
			name: '',
			sorter: false,
			optioner: false,
			dragger: false,
			resizer: false,
			cellTpl: 'checkboxTpl',
			colStyle: {
				minWidth: 'var(--table-checkbox-minwidth)',
				width: 'var(--table-checkbox-width)',
			},
			headerStyle: {
				width: 'var(--table-checkbox-width)',
				minWidth: 'var(--table-checkbox-minwidth)',
			},
			cellStyle: {
				textOverflow: 'clip',
				width: 'var(--table-checkbox-width)',
			},
			order: 0,
			orderMenu: 0
		},
    {
			key: 'title',
			name: 'Title',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
      disabled: true,
			cellTpl: 'titleTpl',
			colStyle: {
				minWidth: '180px',
			},
			headerStyle: {
				minWidth: '180px',
        width: '180px',
			},
			order: 1,
			orderMenu: 1
		},
		{
			key: 'source',
			name: 'Source',
			hidden: !this.showAssetsSource,
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'sourceTpl',
			colStyle: {
				minWidth: '60px',
			},
			headerStyle: {
				minWidth: '60px',
				width: '60px',
			},
			order: 2,
			orderMenu: 2
		},
		{
			key: 'type',
			name: 'Type',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'typeTpl',
			colStyle: {
				minWidth: '80px',
			},
			headerStyle: {
				width: '80px',
				minWidth: '80px',
			},
			order: 3,
			orderMenu: 3
		},
		{
			key: 'description',
			name: 'Description',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: false,
			cellTpl: 'descriptionTpl',
			colStyle: {
				minWidth: '100px',
			},
			headerStyle: {
				width: '140px',
				minWidth: '140px',
			},
			order: 4,
			orderMenu: 4
		},
    {
			key: 'sport',
			name: 'Sport',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'sportTpl',
			colStyle: {
				minWidth: '60px',
			},
			headerStyle: {
				width: '90px',
				minWidth: '60px',
			},
			order: 5,
			orderMenu: 5
		},
    {
			key: 'start',
			name: 'Start',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'startTpl',
			colStyle: {
				minWidth: '60px',
			},
			headerStyle: {
				width: '60px',
				minWidth: '100px',
			},
			order: 6,
			orderMenu: 6
		},
    {
			key: 'end',
			name: 'End',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'endTpl',
			colStyle: {
				minWidth: '60px',
			},
			headerStyle: {
				width: '60px',
				minWidth: '100px',
			},
			order: 7,
			orderMenu: 7
		},
    {
			key: 'duration',
			name: 'Duration',
			sorter: false,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'durationTpl',
			colStyle: {
				minWidth: '80px',
			},
			headerStyle: {
				width: '80px',
				minWidth: '80px',
			},
			order: 8,
			orderMenu: 8
		},
    {
			key: 'camera',
			name: 'Camera label',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'cameraTpl',
			colStyle: {
				minWidth: '95px',
			},
			headerStyle: {
				width: '95px',
				minWidth: '95px',
			},
			order: 9,
			orderMenu: 9
		}, {
			key: 'courtesy',
			name: 'Courtesy',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'courtesyTpl',
			colStyle: {
				minWidth: '60px',
			},
			headerStyle: {
				width: '70px',
				minWidth: '60px',
			},
			order: 10,
			orderMenu: 10
		},
    {
			key: 'cutdown',
			name: 'Cutdown',
			sorter: true,
			optioner: true,
			dragger: true,
			resizer: true,
			cellTpl: 'cutdownTpl',
			colStyle: {
				minWidth: '70px',
			},
			headerStyle: {
				width: '70px',
				minWidth: '70px',
			},
			order: 11,
			orderMenu: 11
		},
    {
			key: 'expand',
			name: '',
			sorter: false,
			optioner: false,
			dragger: false,
			resizer: false,
      sticky: true,
      stickyEnd: true,
			cellTpl: 'expandTpl',
			colStyle: {
				minWidth: '48px'
			},
			cellStyle: {
				padding: '0px'
			},
			headerStyle: {
				width: '48px',
				minWidth: '48px',
				padding: '0px'
			},
			order: 12,
			orderMenu: 12
		}
	];
	private rowsInternal: Original[] = [];

	get rows(): Original[] {
		return this.rowsInternal;
	}
	set rows(rows: Original[]) {
		this.rowsInternal = rows;
		this.rowsChanged$.next();
	}
	activeSort: AssetRowSort = { active: 'title', direction: 'desc' };
	/** Emits when the `rows` input changes. */
	private readonly rowsChanged$ = new ReplaySubject<void>(1);

	/** Emits when navigating to a new page is requested. */
	@Output() readonly page = new EventEmitter<PageEvent>();

	/** Emits when sorting is requested. */
	@Output() readonly sort = new EventEmitter<Sort>();

	/** Emits when filter is changed. */
	@Output() readonly filter = new EventEmitter<AssetRowFilterChange>();

	readonly COLUMN_TO_METADATA_KEY = COLUMN_TO_METADATA_KEY;

	currentSort: Sort = DEFAULT_SORT;

	/** Fixed page size of the asset deletion table. */
	readonly pageSize = 30;

	/** Form control for search input. */
	readonly search = new FormControl<string>('');

	pagination: Pagination<ListResponse<Original>>;

	/** List of columns to render in the table. */
	displayedColumns: Column[] = [...ALL_COLUMNS];

	/** List of rows that have been expanded to reveal their details. */
	readonly expandedRows = new Set<string>();

	/** Current query entered by the user that is be used in asset request. */
	userQuery = '';

	readonly selectedDate$: Observable<DateTime>;

	readonly selectedDateLabel$: Observable<string>;

	readonly todayButtonDisabled$: Observable<boolean>;

	/** On emission triggers table refresh. */
	readonly refresh$ = new Subject<void>();

	readonly expandedAssets = new Set<string>();

	readonly view: StagingView = 'live';

	/** Flag to show site/source filter. */
	readonly enableSiteFilter = this.featureService.featureOn('enable-site-filter');

	/**
	 * Cutdown cache for cutdown details component to restore their state from
	 * when they are re-created.
	 * Having the cache on the LiveStagingTable component allows for simple
	 * lifecycle management of the cache.
	 */
	readonly cutdownCache = new Map<string, AssetCopy[]>();

	/** Selectable sites (possible values for the site filter). */
	readonly selectableSites$ = this.mediaCache.state.selectableSites$;

	/** 'All sites' option (for the site filter). */
	allSitesOption = ALL_SITES_OPTION;

	/** Selected site (in the site filter). */
	selectedSite$ = new BehaviorSubject<Site>(this.allSitesOption);

	/**
	 * Assets filtered by selected site.
	 * This property is used to show/render assets data on the page instead of the
	 * 'assets' property defined in the parent class.
	 */
	filteredAssets: Original[] = [];

	tableId: string;

	constructor(
		private readonly timezoneService: TimezoneService,
		private readonly progressbar: ProgressbarService,
		private readonly analyticsService: FirebaseAnalyticsService,
		private readonly snackbar: SnackBarService,
		private readonly mediaCache: MediaCacheService,
		stagingService: StagingService,
		route: ActivatedRoute,
		tableUtils: TableUtils,
		assetService: AssetService,
		cdr: ChangeDetectorRef,
		stateService: StateService,
		router: Router,
		private readonly featureService: FeatureFlagService,
		private readonly paginationService: PaginationService,
	) {
		super(stagingService, tableUtils, assetService, cdr, stateService, router);
		this.tableId = environment.tableInfoId['liveStagingTable'];

		this.pagination = this.paginationService.getEmptyPagination(this.pageSize);

		this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300))
			.subscribe(query => {
				this.userQuery = query ?? '';
				this.refreshTable();
			});

		this.selectedDate$ = route.queryParamMap.pipe(
			map(params => params.get(LIVE_STAGING_DATE_PARAM)),
			filter((date): date is string => !!date),
			// Format date in pre-defined timezone at 12:00am.
			map(date => this.timezoneService.parseFromIso(date).startOf('day')),
			filter(date => date.isValid),
			shareReplay({ bufferSize: 1, refCount: true }),
		);

		this.selectedDateLabel$ = this.selectedDate$.pipe(
			map(date => date.toLocaleString(DateTime.DATE_FULL, { locale: "en" })));

		this.todayButtonDisabled$ = this.selectedDate$.pipe(
			map(date => date.equals(this.timezoneService.getTodayDate())));

		// Override absent or invalid date with today date.
		const selectedDate =
			route.snapshot.queryParamMap.get(LIVE_STAGING_DATE_PARAM);
		if (!selectedDate || !DateTime.fromISO(selectedDate).isValid) {
			this.changeDate(this.timezoneService.getTodayDate());
		}

		// Listen for the single site selected in the major sites selector.
		this.mediaCache.state.selectedSite$.pipe(take(1)).subscribe(site => {
			this.selectedSite$.next(site);
		});
	}

	override ngOnInit() {
		super.ngOnInit();
		this.startFetchingAssetsOnDateChange();
	}

	async nextDay() {
		const currentDate = await firstValueFrom(this.selectedDate$);
		this.changeDate(currentDate.plus({ days: 1 }));
	}

	async previousDay() {
		const currentDate = await firstValueFrom(this.selectedDate$);
		this.changeDate(currentDate.minus({ days: 1 }));
	}

	today() {
		this.changeDate(this.timezoneService.getTodayDate());
	}

	getMetadataValue(asset: Original, key: MetadataField) {
		const value = asset.assetMetadata.jsonMetadata[key] as unknown;
		if (value == null) return '-';
		if (Array.isArray(value)) return value.join(', ');

		return String(value);
	}

	formatCutdownStatus(asset: Original) {
		const { completedCount, totalCount } = asset.copyStats;
		if (totalCount === 0) return '';

		return `${completedCount} of ${totalCount}`;
	}

	isRowExpanded(row: Original) {
		if (!row.id) { return; }
		return this.expandedRows.has(row.id);
	}

	/** Drops cache and refresh the data for the selected date. */
	async refreshTable() {
		this.assetCache.clear();
		this.refresh$.next();
	}

	/**
	 * Filters assets data (from assets property) by the selected site.
	 * Result is placed into filteredAssets property.
	 */
	filterData() {
		if (!this.assets) return;

		const site = this.selectedSite$?.getValue();
		this.filteredAssets = site === this.allSitesOption ?
			this.assets :
			this.assets.filter(a => a.source?.toLowerCase() === site.siteId.toLowerCase());
	}

	/**
	 * Sorts the assets based on provided `sort`. If no `sort` is provided sorts
	 * based on `this.currentSort`.
	 */
	sortData(sort?: Sort) {
		if (sort) {
			// Update currentSort.
			this.currentSort = (!sort.active || sort.direction === '') ? DEFAULT_SORT : sort;
		}

		if (!this.assets) return;

		this.analyticsService.logEvent('Sort live staging table', {
			eventType: AnalyticsEventType.LOG,
			string1: this.currentSort.active,
			string2: this.currentSort.direction,
		});

		// Apply sort based on currentSort.
		// Here `...` is used to update the ref and trigger mat-table refresh.
		this.assets = [...this.assets].sort((a, b) => {
			const isAsc = this.currentSort.direction === 'asc';
			const sortColumn = this.currentSort.active as Column;
			switch (sortColumn) {
				case 'camera':
				case 'courtesy':
				case 'description':
				case 'sport':
				case 'type':
				case 'title':
					return this.metadataCompare(
						a, b, isAsc, COLUMN_TO_METADATA_KEY[sortColumn]);
				case 'end':
					return this.compare(a.eventEndTime, b.eventEndTime, isAsc);
				case 'start':
					return this.compare(a.eventStartTime, b.eventStartTime, isAsc);
				case 'cutdown':
					return this.compare(
						this.formatCutdownStatus(a), this.formatCutdownStatus(b), isAsc);
				default:
					assumeExhaustiveAllowing<'select' | 'expand' | 'source' | 'duration'>(sortColumn);
					return 0;
			}
		});

		this.filterData();
	}

	getColumnTitleTooltip(field: MetadataField) {
		return `"${field}" metadata field`;
	}

	getStatusIcon(asset: Original) {
		if (this.hasError(asset)) {
			return LiveAssetIcon.ERROR;
		}
		if (asset.state === AssetState.PROCESSING ||
			this.hasCutdownsInProgress(asset)) {
			return LiveAssetIcon.SPINNER;
		}
		if (asset.approved) return LiveAssetIcon.APPROVED;

		return LiveAssetIcon.DEFAULT;
	}

	hasError(asset: Original) {
		return asset.hasError || asset.copyStats.errorCount > 0;
	}

	getStatusTooltip(asset: Original) {
		if (asset.hasError) return asset.errorReason || 'Error';

		if (asset.copyStats.errorCount > 0) return 'Cutdown(s) failed';

		if (this.hasCutdownsInProgress(asset)) {
			return 'Cutdown is in progress';
		}

		if (asset.state === AssetState.PROCESSING) return 'Processing';
		if (asset.approved) return 'Approved';

		return '';
	}

	isExpanded(row: Original) {
		if (!row.id) { return; }
		return this.expandedAssets.has(row.id);
	}

	toggleExpansion(row: Original, activeItems?: ActiveItems | null) {
		if (!row.id) { return; }
		if (!this.expandedAssets.has(row.id)) {
			this.expandedAssets.add(row.id);
			return;
		}

		this.expandedAssets.delete(row.id);

		// Close cut-down metadata panel if it displayed cut-down from the list that
		// is being collapsed.
		if (activeItems?.cutdownParent?.name === row.id) {
			this.stagingService.setActive(undefined);
		}
	}

	protected override updateCache(changedItems: Map<string, Original>) {
		for (const cachedPage of this.assetCache.values()) {
			for (let i = 0; i < cachedPage.length; i++) {
				const changedItem = changedItems.get(cachedPage[i].name);
				if (changedItem) {
					cachedPage[i] = changedItem;
				}
			}
		}
	}

	/** Map of asset results by date, represented by epoch milliseconds. */
	private readonly assetCache = new Map<number, Original[]>();
	public storeAssetsCache(date: DateTime, assets: Original[]): void {
		this.assetCache.set(date.toMillis(), assets);
	}
	/**
	 * Listens to the selected date changes and fetches data for the page
	 * from the cache or the data service. Results are cached until
	 * search query is changed or the component is destroyed.
	 */
	private startFetchingAssetsOnDateChange() {

		merge(
			this.selectedDate$,
			this.refresh$.pipe(switchMap(() => this.selectedDate$)))
			.pipe(
				takeUntil(this.destroyed$), tap(() => {
					this.cdr.markForCheck();
					this.loading = true;
					this.progressbar.show();
				}),
				switchMap(date => {
					const cached = this.assetCache.get(date.toMillis());
					if (cached) {
						return of(cached);
					}
					return this.stagingService
						.getLiveAssets({
							query: this.userQuery,
							date,
						})
						.pipe(tap(assets => {
							if (assets) {
								this.storeAssetsCache(date, assets);
							}
						}));
				}),
				map((assets: Original[] | null) => {
					if (!assets) { return; }

					assets.forEach((asset: Original) => {
						asset['id'] = asset['name'];
            // Calculate duration and convert to seconds
            asset.duration = (asset.eventEndTime - asset.eventStartTime) / 1000;
					});
					return assets;
				}),
				finalize(() => {
					this.progressbar.hide();
				})
			)
			.subscribe(assets => {
				this.loading = false;
				this.scrollTopNeeded.emit();
				this.cdr.markForCheck();
				this.expandedAssets.clear();
				this.progressbar.hide();

				if (!assets) {
					this.assets = [];
					this.snackbar.message('Failed to load live staging assets');
				} else {
					this.assets = assets;
				}
				this.sortData();
				this.refreshActiveAndSelectedItems();
			});
	}

	private changeDate(newDate: DateTime | null) {
		this.router.navigate([], {
			queryParams: { [LIVE_STAGING_DATE_PARAM]: newDate?.toISODate() },
			queryParamsHandling: 'merge',
		});
	}

	private compare<T extends number | string>(a: T, b: T, isAsc: boolean) {
		if (typeof a === 'string' && typeof b === 'string') {
			return a.localeCompare(b) * (isAsc ? 1 : -1);
		}

		return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
	}

	private metadataCompare(
		a: Original, b: Original, isAsc: boolean, key: MetadataField) {
		const aValue = this.getMetadataValue(a, key);
		const bValue = this.getMetadataValue(b, key);
		return this.compare(aValue, bValue, isAsc);
	}

	private hasCutdownsInProgress(asset: Original) {
		const cutdownsInProgress = asset.copyStats.approvedCount -
			asset.copyStats.completedCount - asset.copyStats.errorCount;
		return cutdownsInProgress > 0;
	}

	/**Action - rediecting to metadta panel */
	override selectOrActivate(asset: Original, selectedAssetSet: Set<string>, shiftPressed?: boolean) {
		super.selectOrActivate(asset, selectedAssetSet, shiftPressed);
	}

	onSortByField(rows: Original[]) {
		this.filteredAssets = rows;
		this.cdr.detectChanges();
	}

	onSort(sort: Sort) {
		this.sortData(sort);
		this.sort.emit(sort);
	}

	toggleRowExpanded(row: Original) {
		if (!row.id) { return; }
		if (this.expandedRows.has(row.id)) {
			this.expandedRows.delete(row.id);
		} else {
			this.expandedRows.add(row.id);
		}
	}

	onRowClick(row: Original, selectedAssetSet: Set<string>, shiftPressed = false) {
		this.selectOrActivate(row, selectedAssetSet, shiftPressed);
	}

	selectAssetsSite(site: Site = { siteId: '' } as Site) {
		this.selectedSite$?.next(site);
		this.filterData();
	}

	protected override refreshActiveAndSelectedItems() {
		super.refreshActiveAndSelectedItems();

		this.filterData();
	}
}
