import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';

import { AdminUsersService } from 'admin/admin_users_service';
import { AuthService } from 'auth/auth_service';
import { ViewsShared } from 'ui/ui_table_view.interface';

@Component({
  selector: 'mam-ui-table-views-share-dialog',
  styleUrl: './ui-table-views-share-dialog.component.scss',
  templateUrl: './ui-table-views-share-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponeUiTableViewsShareDialogComponent implements OnInit {
  view: Partial<ViewsShared> = {};
  searchEmail = new FormControl('');
  options: string[] = [];
  filteredOptions$!: Observable<string[]>;

  constructor(
    readonly authService: AuthService,
    readonly usersService: AdminUsersService,
    readonly dialogRef: MatDialogRef<ComponeUiTableViewsShareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewsShared) {}

  ngOnInit(): void {
      this.view = this.data;
      this.getUsersEmail();
  }

  private getUsersEmail() {
    this.usersService.searchUsers()
    .pipe(map(users => users.map(user => user.email)))
    .subscribe({
      next: (options) => {
        this.options = [...new Set(options)].sort();
        this.filteredOptions$ = this.searchEmail.valueChanges.pipe(
          startWith(''),
          map(value => this.filterOptions(value || '')),
        );
      }
    });
  }

  private filterOptions(value: string): string[] {
    const filterValue = value.toLowerCase();
    const { sharedWith } = this.data;
    return this.options
      .filter(option => option.toLowerCase().includes(filterValue)
                    && !sharedWith.includes(option.toLowerCase())
                    && option.toLowerCase() !== this.authService.getUserEmail().toLowerCase());
  }

  addToShare(option: MatAutocompleteSelectedEvent) {
    const sharedWith = this.data?.sharedWith ?? [];
    const list = new Set<string>(sharedWith);
    list.add(option.option.value);
    this.data.sharedWith = [...list];
    this.searchEmail.setValue('');
  }

  removeShared(option: MatChipEvent) {
    const sharedWith = this.data?.sharedWith ?? [];
    const list = new Set<string>(sharedWith);
    list.delete(option.chip.value);
    this.data.sharedWith = [...list];
  }
}
