import {LayoutModule} from '@angular/cdk/layout';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatOptionModule} from '@angular/material/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSelectModule} from '@angular/material/select';
import {MatTableModule} from '@angular/material/table';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';

import { UiTableModule } from 'ui/ui_table.module';

import {AdminModule} from '../admin/admin_module';
import {FeatureFlagModule} from '../feature_flag/feature_flag_module';
import {FirebaseModule} from '../firebase/firebase_module';
import {LiveModule} from '../live/live_module';
import {PipesModule} from '../pipes/pipes_module';
import {PlayerModule} from '../player/player_module';
import {ServicesModule} from '../services/services.module';
import {SharedModule} from '../shared/shared_module';

import {ClipBinPreview} from './clip-bin-preview';
import {Landing} from './landing';
import {RecentAsset} from './recent-asset';
import { SearchListView } from './search-list-view/search-list-view';
import {SearchResults} from './search-results/search-results';
import {SearchFacetGroup} from './search_facet_group';

@NgModule({
  declarations: [
    Landing,
    SearchResults,
    RecentAsset,
    ClipBinPreview,
    SearchFacetGroup,
    SearchListView
  ],
  imports: [
    UiTableModule,
    AdminModule,
    BrowserAnimationsModule,
    CommonModule,
    FeatureFlagModule,
    FirebaseModule,
    FormsModule,
    MatCardModule,
    LayoutModule,
    LiveModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSelectModule,
    MatTableModule,
    MatToolbarModule,
    MatTooltipModule,
    PipesModule,
    PlayerModule,
    ReactiveFormsModule,
    RouterModule,
    ServicesModule,
    SharedModule,
  ],
  exports: [
    RecentAsset,
    ClipBinPreview,
    SearchResults,
    SearchFacetGroup,
  ],
})
export class LandingModule {
}
