<div
    #scrollView
    class="centered-content"
    [class.loading]="progressbar.visible$ | async">

  <!-- Aired on X Section -->
  <ng-container *ngIf="liveService.airedPagination$ | async as pagination">
    <ng-container *ngIf="{ value: airedType$ | async } as airedType">
    <section
        *ngIf="pagination.pageAssets.length"
        class="event-section aired-event-section"
        [ngClass]="{
          'section-aired-today': airedType.value === AiringType.AIRED_TODAY,
          'section-aired-past': airedType.value === AiringType.AIRED_PAST,
        }">
      <div class="asset-results-bar top">
        <div class="asset-results-header">
          <span class="date-tag">
            {{dateLabel$ | async}}
          </span>
          <span class="header-text">{{airedLabel$ | async}}</span>
          <span class="header-text-mobile">{{airedLabelMobile$ | async}}</span>
        </div>
        <div class="paginator">
          <mat-paginator
              class="main__mat-paginator--border"
              hidePageSize
              [length]="pagination.allAssets.length"
              [pageSize]="pagination.pageSize"
              [pageIndex]="pagination.pageIndex"
              (page)="onAiredPageChange($event)">
          </mat-paginator>
        </div>
      </div>
      <ng-container *ngIf="airedColumns$ | async as airedColumns">
        <div class="asset-results"  [class.overflow-hidden]="scrubbingService.scrubbing$ | async" [class]="'grid-columns-' + airedColumns" #airedAssets>
          <ng-container
              *ngFor="let asset of pagination.pageAssets; trackBy: trackAsset">
            <ng-container
                *ngTemplateOutlet="assetPreviewCard; context: {asset: asset, type: 'aired'}">
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="asset-results-bar invisible">
        <div class="paginator">
          <mat-paginator
              class="main__mat-paginator--border"
              [hidePageSize]="true"
              [length]="pagination.allAssets.length"
              [pageSize]="pagination.pageSize"
              [pageIndex]="pagination.pageIndex"
              (page)="onAiredPageChange($event)">
          </mat-paginator>
        </div>
      </div>
    </section>
    </ng-container>
  </ng-container>

  <!-- Live Now Section -->
  <ng-container *ngIf="liveService.airingPagination$ | async as pagination">
    <section
        *ngIf="pagination.pageAssets.length"
        class="event-section airing-event-section section-live-now">
      <div class="asset-results-bar top">
        <div class="asset-results-header">
          <span class="date-tag">
            {{dateLabel$ | async}}
          </span>
          <span class="header-text">Live Now</span>
        </div>
        <div class="paginator">
          <mat-paginator
              class="main__mat-paginator--border"
              hidePageSize
              [length]="pagination.allAssets.length"
              [pageSize]="pagination.pageSize"
              [pageIndex]="pagination.pageIndex"
              (page)="onAiringPageChange($event)">
          </mat-paginator>
        </div>
      </div>
     <ng-container *ngIf="columnsMap$ | async as columnsMap">
        <div class="asset-results" [class.overflow-hidden]="scrubbingService.scrubbing$ | async" [class]="'grid-columns-' + columnsMap[AiringType.LIVE_NOW]" #airingAssets>
          <ng-container
              *ngFor="let asset of pagination.pageAssets; trackBy: trackAsset">
            <ng-container
                *ngTemplateOutlet="assetPreviewCard; context: {asset: asset, type: 'airing'}">
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="asset-results-bar">
        <div class="paginator">
          <mat-paginator
              class="main__mat-paginator--border"
              [hidePageSize]="true"
              [length]="pagination.allAssets.length"
              [pageSize]="pagination.pageSize"
              [pageIndex]="pagination.pageIndex"
              (page)="onAiringPageChange($event)">
          </mat-paginator>
        </div>
      </div>
    </section>
  </ng-container>

  <!-- Upcoming Section -->
  <ng-container *ngIf="liveService.scheduledAssets$ | async as scheduledAssets">
    <section *ngIf="scheduledAssets.length"
        class="event-section scheduled-event-section">
      <div class="asset-results-bar top">
        <div class="asset-results-header">
          <span class="date-tag">
            {{dateLabel$ | async}}
          </span>
          <span class="header-text">Upcoming</span>
        </div>
      </div>
      <div class="scheduled-events">
        <table mat-table
            [trackBy]="trackAsset"
            [dataSource]="scheduledAssets">

          <!-- Scheduled Time Column -->
          <ng-container matColumnDef="time">
            <th mat-header-cell *matHeaderCellDef>Time</th>
            <td mat-cell *matCellDef="let asset">
              {{asset.eventStartTime | tzdate : 'HH:mm'}}
              -
              {{asset.eventEndTime | tzdate : 'HH:mm'}}
            </td>
          </ng-container>

          <!-- Title -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let asset" [title]="asset.title">
              <span matTooltipClass="mobile-tooltip" [matTooltip]="asset.title">
                {{asset.title}}
              </span>
            </td>
          </ng-container>

          <!-- Source Column -->
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Source</th>
            <td
                mat-cell
                *matCellDef="let asset"
                [title]="asset.assetMetadata.jsonMetadata[MetadataField.SOURCE_ROUTER]">
              {{asset.assetMetadata.jsonMetadata[MetadataField.SOURCE_ROUTER]}}
            </td>
          </ng-container>

          <!-- Courtesy Column -->
          <ng-container matColumnDef="courtesy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Courtesy</th>
            <td
                mat-cell
                *matCellDef="let asset"
                [title]="asset.assetMetadata.jsonMetadata[MetadataField.COURTESY]">
              {{asset.assetMetadata.jsonMetadata[MetadataField.COURTESY]}}
            </td>
          </ng-container>

          <!-- Teams Column -->
          <ng-container matColumnDef="teams">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Teams</th>
            <td mat-cell *matCellDef="let asset" [title]="getTeamsText(asset)">
              <span matTooltipClass="mobile-tooltip" [matTooltip]="getTeamsText(asset)">
                {{getTeamsText(asset)}}
              </span>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayColumns;">
          </tr>
        </table>
      </div>
    </section>
  </ng-container>

  <!-- Content displayed when there are no live assets found -->
  <ng-container *ngIf="{
      aired: liveService.airedPagination$ | async,
      airing: liveService.airingPagination$ | async,
      scheduled: liveService.scheduledAssets$ | async,
  } as state">
    <ng-container *ngIf="
        state.aired && state.airing && state.scheduled &&
        !state.aired?.allAssets?.length &&
        !state.airing?.allAssets?.length &&
        !state.scheduled?.length">
      <div class="empty-contents">
        <div class="image-container">
          <img alt="No live events" src="images/empty_contents_logo.svg">
        </div>
        <h1>No Live Events Found</h1>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Asset preview card -->
<ng-template #assetPreviewCard let-asset="asset" let-type="type">
  <mam-asset-preview
      class="asset-preview"
      [routerLink]="asset.renditions.length ? ['/asset', asset.name] : []"
      [queryParams]="{'type': type}"
      queryParamsHandling="merge"
      [asset]="asset"
      [tag]="AssetPreviewTag.CAMERA_COUNT"
      (thumbnailLoad)="onThumbnailLoaded()"
      mam-firebase-ga="Open {{asset.isLive ? 'live' : 'VoD'}} asset from live landing"
      [mam-firebase-resource]="asset.name">
  </mam-asset-preview>
</ng-template>
