import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, signal} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {of, ReplaySubject} from 'rxjs';
import {debounceTime, finalize, map, switchMap, takeUntil, tap} from 'rxjs/operators';

import {ApiSortByOption} from 'api/ias/model/api-sort-by-option';
import {assertTruthy, assumeExhaustiveAllowing} from 'asserts/asserts';
import { environment } from 'environments/environment';
import {TableCol} from 'ui/ui_table.type';

import {isErrorResponse} from '../error_service/error_response';
import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {VcmsField, VcmsQueryExpressions} from '../query_expressions/vcms_query_expressions';
import {StagingService, StagingView} from '../right_panel/staging_service';
import {AssetService, AssetState, ListResponse, Original} from '../services/asset_service';
import {ApiAssetState} from '../services/ias_types';
import {PaginatorIntl, UNKNOWN_LENGTH} from '../services/paginator-intl';
import {PreferencesService} from '../services/preferences_service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {StateService} from '../services/state_service';
import {TableUtils} from '../services/table_utils';
import { TaskStatus } from '../services/transfer_service';



import {MultiSelectOption, MultiSelectOptions} from './multiselect_table_header';
import {StagingTable} from './staging_table_base';

export const ALL_COLUMNS = [
  'select',
  'title',
  'schema',
  'source',
  'date',
  'duration',
  'status',
  'location'
] as const;

type Column = typeof ALL_COLUMNS[number];

interface VodStagingSort extends Sort {
  active: Column;
}

/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [30, 50, 100, 200];

/** Default page size */
const DEFAULT_PAGE_SIZE = 100;

/** When page size selector is disabled we mimic older MAM UI */
const LEGACY_PAGE_SIZE = 30;

const DEFAULT_SORT: VodStagingSort = {
  active: 'date',
  direction: 'desc'
};

/**
 * Icons used to indicate different asset states. SPINNER is a pseudo-icon that
 * will be rendered as animated spinner.
 */
const enum VodAssetIcon {
  SPINNER = 'spinner',
  APPROVED = 'check',
  ERROR = 'error',
  DEFAULT = 'insert_drive_file'
}

/**
 * Vod content staging table.
 */
@Component({
  selector: 'mam-vod-staging-table',
  templateUrl: './vod_staging_table.ng.html',
  styleUrls: ['./vod_staging_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: MatPaginatorIntl, useClass: PaginatorIntl}],
})
export class VodStagingTable extends StagingTable implements OnInit {

  selection = new SelectionModel<unknown>(true,[]);

  cols: TableCol[] = [
    {
      key: 'checkbox',
      name: '',
      resizer:false,
      dragger: false,
      headerTpl: 'checkBoxTpl',
      cellTpl: 'checkBoxTpl',
      order: 0,
      colStyle: {
        minWidth: 'var(--table-checkbox-minwidth)',
        width: 'var(--table-checkbox-width)',
      },
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
      },
      cellStyle: {
        textOverflow: 'clip',
        width: 'var(--table-checkbox-width)',
      },
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title/File name',
      optioner: true,
      sorter: true,
      dragger: true,
      resizer: true,
      disabled: true,
      order: 1,
      cellTpl: 'nameTpl',
      colStyle: {
        minWidth: '100px',
      },
      headerStyle: {
        width: '100px',
        minWidth: '100px'
      },
      orderMenu: 1
    },
    {
      key: 'source',
      name: 'Source',
      optioner: true,
      dragger: true,
      resizer: true,
      order: 2,
      headerTpl: 'sourceTpl',
      colStyle: {
        minWidth: '80px',
      },
      headerStyle: {
        width: '100px',
        minWidth: '100px',
      },
      orderMenu: 2
    },
    {
      key: 'createTime',
      name: 'Date',
      optioner: true,
      dragger: true,
      resizer: true,
      sorter: true,
      pipe: 'tzdate',
      pipeArg: 'MMM d, y, h:mm a',
      colStyle: {
        minWidth: '120px',
      },
      headerStyle: {
        width: '180px',
        minWidth: '180px',
      },
      cellStyle: {
        textOverflow: 'clip'
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'duration',
      name: 'Duration',
      dragger: true,
      resizer: true,
      sorter: false,
      cellTpl: 'durationTpl',
      colStyle: {
        minWidth: '80px',
      },
      headerStyle: {
        width: '100px',
        minWidth: '100px',
      },
      cellStyle: {
        textOverflow: 'clip'
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'status',
      name: 'Status',
      optioner: true,
      dragger: true,
      resizer: true,
      headerTpl: 'statusTpl',
      cellTpl: 'statusTpl',
      colStyle: {
        minWidth: '116px'
      },
      headerStyle: {
        width: '116px',
        minWidth: '116px',
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'schema',
      name: 'Schema Name',
      optioner: true,
      dragger: true,
      resizer: true,
      cellTpl: 'schemaTpl',
      colStyle: {
        minWidth: '80px'
      },
      headerStyle: {
        width: '120px',
        minWidth: '80px',
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'storage',
      name: 'Storage',
      dragger: true,
      resizer: false,
      stickyEnd: true,
      cellTpl: 'storageTpl',
      colStyle: {
        minWidth: '71px'
      },
      headerStyle: {
        width: '71px',
        textAlign: 'right',
        minWidth: '71px'
      },
      cellStyle: {
        textAlign: 'right',
        textOverflow: 'clip'
      },
      class: ['th-align-right'],
      order: 7,
      orderMenu: 7
    }
  ];

  rows: unknown[] = [];

  /** Expose status enumeration to template. */
  readonly TaskStatus = TaskStatus;

  displayedColumns = signal<Column[]>([]);

  pageSize = this.restorePageSize();

  /** Form control for search input. */
  search = new FormControl<string>('');

  /** Current query entered by the user that is be used in asset request. */
  userQuery = '';

  readonly dataRequest$ =
      new ReplaySubject<{pageIndex: number, pageSize: number}>(1);

  currentPageIndex = 0;

  /** Total asset count across all pages. */
  totalCount = UNKNOWN_LENGTH;

  /** Page size options for vod table. */
  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  readonly view: StagingView = 'vod';

  /** Options for status column filter. */
  statusFilterOptions: MultiSelectOptions<ApiAssetState> = [
    {title: 'Any Status', selected: true},
    {title: 'Ready', value: 'STATE_READY'},
    {title: 'Processing', value: 'STATE_PROCESSING'},
  ];

  activeSort: VodStagingSort = {...DEFAULT_SORT};

  /** Flag to show assets' source column. */
  readonly showAssetsSource = this.featureService.featureOn('show-user-information');
  tableId:string;

  constructor(
      readonly featureService: FeatureFlagService,
      private readonly progressbar: ProgressbarService,
      private readonly preferences: PreferencesService,
      private readonly snackbar: SnackBarService,
      private readonly elementRef: ElementRef,
      private readonly vcmsExpressions: VcmsQueryExpressions,
      stagingService: StagingService,
      assetService: AssetService,
      tableUtils: TableUtils,
      cdr: ChangeDetectorRef,
      stateService: StateService,
      router: Router
  ) {
    super(stagingService, tableUtils, assetService, cdr, stateService, router);
    this.tableId = environment.tableInfoId['vodStagingTable'];

    this.search.valueChanges.pipe(takeUntil(this.destroyed$), debounceTime(300))
        .subscribe(query => {
          this.scrollTopNeeded.emit();
          this.userQuery = query ?? '';
          this.refreshTable();
        });

    this.startResponsiveLayout();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.startFetchingAssetsOnPageChange();
    // Trigger asset fetching.
    this.dataRequest$.next({pageIndex: 0, pageSize: this.pageSize});
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.rows.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.rows.forEach(row => this.selection.select(row));
  }

  changePage({pageIndex, pageSize}: PageEvent) {
    this.scrollTopNeeded.emit();
    this.dataRequest$.next({pageIndex, pageSize});
  }

  formatStatus(asset: Original) {
    // STATE_ERROR
    if (asset.hasError) return 'Error';

    // STATE_READY
    if (asset.state === AssetState.VOD) return 'Ready';

    // STATE_PROCESSING
    if (asset.state === AssetState.PROCESSING) return 'Processing';

    // Unreachable unless there is an empty asset or mis-indexed live asset
    // returned by api.
    return 'Unknown';
  }

  /** Refreshes the table from the first page. */
  refreshTable() {
    this.stateService.togglePersistentPanel$.next(false);
    this.dataRequest$.next({pageIndex: 0, pageSize: this.pageSize});
  }

  onSortChanged(sort: Sort) {
    if (sort.active === 'createTime') {
      sort.active = 'date';
    }
    this.activeSort = sort as VodStagingSort;

    this.refreshTable();
  }

  onStatusFilterChanged(option: MultiSelectOption) {
    this.statusFilterOptions = this.statusFilterOptions.map(opt => {
      opt.selected = opt.title === option.title;
      return opt;
    });
    this.refreshTable();
  }

  getStatusIcon(asset: Original) {
    if (asset.hasError) return VodAssetIcon.ERROR;
    if (asset.state === AssetState.PROCESSING) return VodAssetIcon.SPINNER;
    if (asset.approved) return VodAssetIcon.APPROVED;

    return VodAssetIcon.DEFAULT;
  }

  getStatusTooltip(asset: Original) {
    if (asset.hasError) return asset.errorReason || 'Error';
    if (asset.state === AssetState.PROCESSING) return 'Processing';
    if (asset.approved) return 'Approved';

    return '';
  }

  protected override updateCache(changedItems: Map<string, Original>) {
    for (const cachedPage of this.assetListResponseCache.values()) {
      for (let i = 0; i < cachedPage.assets.length; i++) {
        const changedItem = changedItems.get(cachedPage.assets[i].name);
        if (changedItem) {
          cachedPage.assets[i] = changedItem;
        }
      }
    }
  }

  /**
   * Contains cached asset list responses. Array index correlates with
   * pageIndex.
   */
  private assetListResponseCache: Array<ListResponse<Original>> = [];

  /**
   * Listens to the emissions from `dataRequest$` and if `refresh` param is off
   * fetches data for the requested page from the cache or the data service.
   * When `refresh` is true, drops the whole cache and fetches data from first
   * page and up to the requested one.
   *
   * Paginator has to be manually disabled until the request is done because we
   * can't get `N+1` page until we have result for `N` because we would lack
   * the `nextPageToken`.
   */
  private startFetchingAssetsOnPageChange() {
    this.dataRequest$
        .pipe(
            takeUntil(this.destroyed$), tap(() => {
              this.cdr.markForCheck();
              this.loading = true;
              this.progressbar.show();
            }),
            switchMap(({pageIndex, pageSize}) => {
              // When page size is changed store new value and drop the user to
              // the first page. This will clear the cache as well (line 183)
              if (pageSize !== this.pageSize) {
                pageIndex = 0;
                this.pageSize = pageSize;
                this.storePageSize(pageSize);
              }

              // Always re-fetch the first page and drop the cache.
              if (pageIndex === 0) {
                this.assetListResponseCache = [];
                this.totalCount = UNKNOWN_LENGTH;
              } else {
                const cached = this.assetListResponseCache[pageIndex];
                if (cached) return of({response: cached, pageIndex});
              }

              const mustUsePageToken = pageIndex !== 0;
              const pageToken = mustUsePageToken ?
                  this.assetListResponseCache[pageIndex - 1]?.nextPageToken :
                  undefined;
              if (mustUsePageToken) {
                assertTruthy(
                    pageToken,
                    `Vod Staging: No page token for page index ${pageIndex}`);
              }
              return this.stagingService
                  .getVodAssets({
                    pageSize: this.pageSize,
                    query: this.buildQuery(),
                    pageToken,
                    sortOptions: this.buildSortOptions(),
                  })
                  .pipe(map(response => ({response, pageIndex})));
            }),
            finalize(() => {
              this.progressbar.hide();
            }))
        .subscribe(({response, pageIndex}) => {
          this.cdr.markForCheck();
          this.loading = false;
          this.progressbar.hide();
          this.currentPageIndex = pageIndex;

          if (isErrorResponse(response)) {
            this.snackbar.message('Failed to load staging assets');
            this.assets = [];

            //review
            this.rows = [];

            // Prevent further navigation after an error.
            this.totalCount = this.currentPageIndex * this.pageSize;
            this.refreshActiveAndSelectedItems();
            return;
          }

          this.assetListResponseCache[pageIndex] = response;
          this.assets = response.assets;
          //review
          this.rows = response.assets;

          this.refreshActiveAndSelectedItems();

          if (!response.nextPageToken) {
            // This is the last page, set total count which will disable next
            // page in the paginator.
            this.totalCount =
                this.currentPageIndex * this.pageSize + this.assets.length;
          }
        });
  }

  private startResponsiveLayout() {
    this.tableUtils
        .observeWidth(
            this.elementRef.nativeElement,
            [
              {name: 'S', minWidth: 0, add: ['select','title', 'source', 'date','status', 'schema', 'location']},
            ])
        .pipe(takeUntil(this.destroyed$))
        .subscribe(({columns}) => {
          assertTruthy(columns, 'At least one breakpoint should be activated.');
          this.cdr.markForCheck();
          this.displayedColumns.set(columns);
        });
  }

  private restorePageSize() {
    const pageSize = Number(this.preferences.load('vod_staging_page_size'));
    const defaultPageSize =
        this.featureService.featureOn('use-vod-staging-page-size-selector') ?
        DEFAULT_PAGE_SIZE :
        LEGACY_PAGE_SIZE;
    return PAGE_SIZE_OPTIONS.includes(pageSize) ? pageSize : defaultPageSize;
  }

  private storePageSize(pageSize: number) {
    // Don't store page size when page selector is not available.
    if (this.featureService.featureOff('use-vod-staging-page-size-selector')) {
      return;
    }
    this.preferences.save('vod_staging_page_size', String(pageSize));
  }

  private buildQuery() {
    const selectedStatus = this.statusFilterOptions.find(opt => opt.selected);
    if (!selectedStatus?.value) return this.userQuery;

    return this.vcmsExpressions.and([
      this.userQuery,
      this.vcmsExpressions.is('AssetState', selectedStatus.value),
    ]);
  }

  private buildSortOptions(): ApiSortByOption[]|undefined {
    let field: VcmsField;
    switch (this.activeSort.active) {
      case 'date':
        field = 'AssetCreateTime';
        break;
      case 'title':
        field = 'title';
        break;
      default:
        assumeExhaustiveAllowing<'select'|'schema'|'status'|'location' | 'duration' |'source'>(
            this.activeSort.active);
        return undefined;
    }

    return [{
      fieldKey: field,
      sortDecreasing: this.activeSort.direction === 'desc',
    }];
  }

  onRowClick(row: Original, selectedAssetSet: Set<string>, shiftPressed = false) {
    this.selectOrActivate(row, selectedAssetSet, shiftPressed);
  }

  onSortByField(rows: Original[]) {
    this.rows = rows;
    this.cdr.detectChanges();
  }
}
