<ng-container *ngIf="{
  pendingApproval: stagingService.pendingApproval$ | async,
  isEditing: stagingService.isEditing$ | async,
  selectedAssetSet: stagingService.selectedAssetSet$ | async,
  activeItems: stagingService.activeItems$ | async,
  activeItemSet: stagingService.activeItemSet$ | async
} as tplState">

  <ng-container *ngIf="assets &&
      tplState.pendingApproval &&
      tplState.isEditing != null &&
      tplState.selectedAssetSet &&
      tplState.activeItemSet &&
      getSelectionInfo(assets, tplState.selectedAssetSet) as selectionInfo"
  >

    <ng-container *ngIf="selectedSite$ | async as selectedSite">
      <button
          *ngIf="enableSiteFilter"
          mat-stroked-button
          mam-firebase-ga="Open site picker"
          [mam-firebase-resource]="selectedSite.name"
          [matMenuTriggerFor]="sitesMenu"
          class="site-select">
          <mat-icon>domain</mat-icon>
          <span>{{selectedSite.siteId | uppercase}}</span>
          <mat-icon iconPositionEnd class="arrow">arrow_drop_down</mat-icon>
      </button>
      <mat-menu #sitesMenu="matMenu">
        <button
            mat-menu-item
            (click)="selectAssetsSite(allSitesOption)"
            mam-firebase-ga="Select a site"
            mam-firebase-resource="All Sites">
          <mat-icon>domain</mat-icon>
          <span>{{allSitesOption.siteId}}</span>
        </button>
        <button
            *ngFor="let site of selectableSites$ | async"
            mat-menu-item
            (click)="selectAssetsSite(site)"
            mam-firebase-ga="Select a site"
            [mam-firebase-resource]="site.name">
          <mat-icon>domain</mat-icon>
          <span>{{site.siteId | uppercase}}</span>
        </button>
      </mat-menu>
    </ng-container>

    <!-- Actions / Search bar -->
    <div class="actions-search-bar-container">
      <div class="action-bar-container">
        <div class="video-actions action_checkbox">

          <!-- (De)Select all toggle. -->
          <mat-checkbox
              color="primary"
              [disabled]="!assets.length || loading || tplState.isEditing"
              (change)="selectionInfo.areAll
                          ? stagingService.select([])
                          : stagingService.select(selectionInfo.selectableItems)"
              [checked]="selectionInfo.areAll"
              [indeterminate]="selectionInfo.indeterminate"
              [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
              [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)"
              matTooltipPosition="above"
              mam-firebase-ga="Toggle selection for all assets in live staging">
          </mat-checkbox>

          <!-- Actions for selected assets. -->
          <ng-container *ngIf="selectionInfo.areAny">
            <!-- Approve -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="approve-selected main__icon--size"
                aria-label="Approve selected assets"
                matTooltip="Approve selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Approve selected assets in live staging"
                (click)="approve(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">check_circle</mat-icon>
            </button>
            <!-- Add to clip bin -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="add-selected-to-bins main__icon--size"
                aria-label="Add selected assets to clip bins"
                matTooltip="Add selected assets to clip bins"
                matTooltipPosition="above"
                mam-firebase-ga="Add selected assets to clip bins in live staging"
                (click)="addClipsToBins(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">add_box</mat-icon>
            </button>
            <!-- Edit -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="edit-selected main__icon--size"
                aria-label="Edit selected assets"
                matTooltip="Edit selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Bulk edit in live staging"
                (click)="edit(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
            </button>
            <!-- On-prem TTL Extension -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="extend-ttl-selected main__icon--size"
                aria-label="Extend TTL on selected assets"
                matTooltip="Extend TTL on selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Extend TTL on selected assets in live staging"
                (click)="extendAssetsTtl(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">more_time</mat-icon>
            </button>
            <!-- On-prem purge -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                (click)="purgeAssets(selectionInfo.selectedItems)"
                class="purge-selected main__icon--size"
                aria-label="Delete on-prem files"
                matTooltip="Delete on-prem files"
                matTooltipPosition="above"
                mam-firebase-ga="Purge live staging selection">
              <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
            </button>
            <!-- Delete -->
            <button
                [disabled]="loading || tplState.isEditing"
                mat-icon-button
                class="delete-selected main__icon--size"
                aria-label="Delete selected assets"
                matTooltip="Delete selected assets"
                matTooltipPosition="above"
                mam-firebase-ga="Delete selected assets in live staging"
                (click)="deleteAssets(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
          </ng-container>
        </div>
        <div class="video-actions">
          <button
              [disabled]="loading || tplState.isEditing"
              class="refresh-button main__icon--size"
              mat-icon-button
              aria-label="Refresh the list"
              matTooltip="Refresh the list"
              matTooltipPosition="above"
              mam-firebase-ga="Refresh table in live staging"
              (click)="refreshTable()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
      <div class="search-bar-container">
        <mat-form-field
            appearance="outline"
            class="search-bar"
            subscriptSizing="dynamic"
            [class.disabled]="tplState.isEditing">
          <mat-icon matPrefix>search</mat-icon>
          <input type="text"
              [readonly]="tplState.isEditing"
              matInput
              [formControl]="search"
              placeholder="Search live assets"
              aria-label="Search live assets"
              autocomplete="off">
          <button [disabled]="tplState.isEditing"
              *ngIf="search.value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              mam-firebase-ga="Clear search query in live staging"
              (click)="search.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div class="calendar-navigation">
          <button
              mat-flat-button
              class="today"
              [disabled]="tplState.isEditing || (todayButtonDisabled$ | async)"
              mam-firebase-ga="Go to today in live staging"
              aria-label="Today"
              (click)="today()">
            Today
          </button>
          <button
              [disabled]="tplState.isEditing"
              class="previous"
              matTooltip="Previous day"
              matTooltipPosition="above"
              mat-icon-button
              mam-firebase-ga="Go to previous day in live staging"
              aria-label="Previous day"
              (click)="previousDay()">
            <mat-icon>chevron_left</mat-icon>
          </button>
          <button
              [disabled]="tplState.isEditing"
              id="ButtonNextDay"
              class="next"
              matTooltip="Next day"
              matTooltipPosition="above"
              mat-icon-button
              mam-firebase-ga="Go to next day in live staging"
              aria-label="Next day"
              (click)="nextDay()">
            <mat-icon>chevron_right</mat-icon>
          </button>
          <span class="date-label">{{selectedDateLabel$ | async}}</span>
        </div>
      </div>
    </div>



    <mam-ui-table id="LiveStagingTable" *ngIf="assets?.length"
      class="w-100"
      [cols]="cols"
      [data]="assets || []"
      [options]="{
        tableInfo: {
          name: 'liveStagingTable',
          id: tableId
        },
        multi: true,
        mainColIdx: 0
      }"
      tableType="site-selector"
      [expandedRows]="expandedRows"
      [selectedRows]="tplState.selectedAssetSet"
      [activeRows]="tplState.activeItemSet"
      [activeSort]="activeSort"
      (sortClick)="onSort($event)"
      (rowClick)="onRowClick($event,tplState.selectedAssetSet)"
    >
      <ng-template #cellTpl let-row let-col="col">
        <ng-container *ngIf="col.cellTpl === 'checkboxTpl'">
          <div (click)="
            $event.stopPropagation();
            toggleSelection(row, tplState.selectedAssetSet, $event.shiftKey)">
            <mat-checkbox
              *ngIf="canBeSelected(row)"
              [disabled]="tplState.isEditing"
              color="primary"
              (click)=" $event.stopPropagation()"
              (change)="toggleSelection(row, tplState.selectedAssetSet)"
              [checked]="tplState.selectedAssetSet.has(row.name)"
              [aria-label]="tableUtils.checkboxLabel(row, tplState.selectedAssetSet)"
              mam-firebase-ga="Toggle asset checkbox in live staging"
              [mam-firebase-resource]="row.name">
            </mat-checkbox>
          </div>
        </ng-container>

        <!-- Title -->
        <ng-container *ngIf="col.cellTpl === 'titleTpl'">
          <div class="icon-with-text">
            <mam-asset-info-icon-button
                [asset]="row"
                [icon]="getStatusIcon(row)"
                [showIconForExternalUsers]="true"
                [tooltip]="getStatusTooltip(row)">
            </mam-asset-info-icon-button>
            <span matTooltipClass="mobile-tooltip" matTooltip="{{getMetadataValue(row, COLUMN_TO_METADATA_KEY.title)}}"
                [title]="getMetadataValue(row, COLUMN_TO_METADATA_KEY.title)">
              {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.title)}}
            </span>
          </div>
        </ng-container>

        <!-- Source-->
        <ng-container *ngIf="col.cellTpl === 'sourceTpl'">
          {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.source)}}
        </ng-container>

        <!-- Type -->
        <ng-container *ngIf="col.cellTpl === 'typeTpl'">
          {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.type)}}
        </ng-container>

        <!-- Description -->
        <ng-container *ngIf="col.cellTpl === 'descriptionTpl'">
          {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.description)}}
        </ng-container>

        <!-- Sport -->
        <ng-container *ngIf="col.cellTpl === 'sportTpl'">
          {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.sport)}}
        </ng-container>

        <!-- Start time -->
        <ng-container *ngIf="col.cellTpl === 'startTpl'">
          {{row.eventStartTime | tzdate : 'HH:mm (Z)'}}
        </ng-container>

        <!-- End time -->
        <ng-container *ngIf="col.cellTpl === 'endTpl'">
          {{row.eventEndTime | tzdate : 'HH:mm (Z)'}}
        </ng-container>

        <!-- Duration -->
        <ng-container *ngIf="col.cellTpl === 'durationTpl'">
            {{row?.duration | duration  }}
        </ng-container>

        <!-- Camera -->
        <ng-container *ngIf="col.cellTpl === 'cameraTpl'">
          {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.camera)}}
        </ng-container>

        <!-- Courtesy -->
        <ng-container *ngIf="col.cellTpl === 'courtesyTpl'">
          {{getMetadataValue(row, COLUMN_TO_METADATA_KEY.courtesy)}}
        </ng-container>

        <!-- Cutdown -->
        <ng-container *ngIf="col.cellTpl === 'cutdownTpl'">
          {{formatCutdownStatus(row)}}
        </ng-container>

        <!-- Expand row -->
        <ng-container *ngIf="col.cellTpl === 'expandTpl'">
          <div>
            <button
              (click)="$event.stopPropagation(); this.toggleRowExpanded(row)"
              mat-icon-button
              mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} asset table row"
              [mam-firebase-resource]="row.name"
              [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'">
              <mat-icon>
                {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}}
              </mat-icon>
            </button>
          </div>
        </ng-container>

        <ng-template #multiTpl let-row>
          <mam-cut-down-details
                *ngIf="isExpanded(row)"
                [asset]="row"
                [cutdownCache]="cutdownCache">
            </mam-cut-down-details>
        </ng-template>

      </ng-template>



    </mam-ui-table>




    <div *ngIf="assets?.length === 0" class="empty-message">
      No assets available
    </div>
  </ng-container>
</ng-container>
