import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router, UrlCreationOptions } from '@angular/router';
import { map, Observable, ReplaySubject, takeUntil } from 'rxjs';

import { environment } from 'environments/environment';
import { FirebaseAnalyticsService } from 'firebase/firebase_analytics_service';
import { FirebasePerformanceService, Trace, TraceName } from 'firebase/firebase_performance_service';
import { PluginService } from 'plugin/plugin_service';
import { StagingService } from 'right_panel/staging_service';
import { MetadataField } from 'services/asset_api_service';
import { Original } from 'services/asset_service';
import { PreferencesService } from 'services/preferences_service';
import { ProgressbarService } from 'services/progressbar_service';
import { SearchInputService } from 'services/search_input_service';
import { SearchMode, SearchType } from 'services/search_service';
import { StateService } from 'services/state_service';
import { TableUtils } from 'services/table_utils';
import { DisplayMode, PaddedSegment, VodSearchService } from 'services/vod_search_service';
import { BatchOperationService } from 'shared/batch_operation_service';
import { RowEvent, TableCol, TableSort } from 'ui/ui_table.type';

interface SearchResponse {
  segments: PaddedSegment[];
  isInitialFacetsResponse: boolean;
}


/** Page size options for list view. */
const PAGE_SIZE_OPTIONS = [30, 50, 100, 200];


@Component({
  selector: 'mam-search-list-view',
  templateUrl: './search-list-view.ng.html',
  styleUrl: './search-list-view.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchListView implements OnDestroy {
  /** Asset that will be use for multi-select (shift+click) operations.  */
  private selectionAnchorAssetName = '';
  readonly PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  /**
   * Search request results. Contain padded segments for the red areas on each
   * card, and whether they are the result of the initial facets empty response.
   */
  readonly results$: Observable<SearchResponse | undefined>;

  /** Indicates what search was used for the recent search. */
  readonly searchMode$: Observable<SearchMode | undefined>;

  readonly SearchMode = SearchMode;

  private readonly performanceTrace?: Trace;
  private readonly destroyed$ = new ReplaySubject<void>(1);

  cols: TableCol[] = [
    {
      key: 'checkbox',
      name: '',
      resizer:false,
      dragger: false,
      headerTpl: 'checkBoxTpl',
      cellTpl: 'checkBoxTpl',
      colStyle: {
        minWidth: 'var(--table-checkbox-minwidth)',
      },
      headerStyle: {
        width: 'var(--table-checkbox-width)',
        minWidth: 'var(--table-checkbox-minwidth)',
      },
      cellStyle: {
        textOverflow: 'clip'
      },
      order: 0,
      orderMenu: 0
    },
    {
      key: 'title',
      name: 'Title / File name',
      optioner: true,
      resizer: true,
      sorter: true,
      dragger: true,
      disabled: true,
      cellTpl: 'nameTpl',
      colStyle: {
        minWidth: '200px',
      },
      headerStyle: {
        width: '440px',
        minWidth: '200px'
      },
      classCel: ['main-column'],
      class: ['icon-with-text'],
      order: 1,
      orderMenu: 1
    },
    {
      key: 'duration',
      name: 'Duration',
      optioner: true,
      resizer: true,
      dragger: true,
      cellTpl: 'durationTpl',
      colStyle: {
        minWidth: '80px',
      },
      headerStyle: {
        width: '80px',
        minWidth: '80px'
      },
      order: 2,
      orderMenu: 2
    },
    {
      key: 'content-type',
      name: 'Content Type',
      optioner: true,
      resizer: true,
      dragger: true,
      cellTpl: 'contentTypeTpl',
      colStyle: {
        minWidth: '100px',
      },
      headerStyle: {
        width: '100px',
        minWidth: '100px'
      },
      order: 3,
      orderMenu: 3
    },
    {
      key: 'event-time',
      name: 'Event Time',
      optioner: true,
      resizer: true,
      dragger: true,
      cellTpl: 'eventTimeTpl',
      colStyle: {
        minWidth: '135px',
      },
      headerStyle: {
        width: '135px',
        minWidth: '135px'
      },
      order: 4,
      orderMenu: 4
    },
    {
      key: 'last-modified',
      name: 'Last Modified',
      optioner: true,
      resizer: true,
      dragger: true,
      sorter: true,
      cellTpl: 'lastModifiedTpl',
      colStyle: {
        minWidth: '110px',
      },
      headerStyle: {
        width: '110px',
        minWidth: '110px'
      },
      order: 5,
      orderMenu: 5
    },
    {
      key: 'location',
      name: 'Storage',
      optioner: true,
      resizer: true,
      cellTpl: 'storageTpl',
      colStyle: {
        minWidth: '60px',
      },
      cellStyle: {
        textAlign: 'center',
        paddingRight: '0',
      },
      headerStyle: {
        width: '60px',
        minWidth: '60px'
      },
      order: 6,
      orderMenu: 6
    },
    {
      key: 'action',
      name: '',
      sticky: true,
      stickyEnd: true,
      dragger: false,
      cellTpl: 'actionTpl',
      cellStyle: {
        textAlign: 'center'
      },
      colStyle: {
        minWidth: '48px',
        textAlign: 'center'
      },
      headerStyle: {
        width: '48px',
        minWidth: '48px'
      },
      order: 7,
      orderMenu: 7
    }
  ];

  tableId:string;

  constructor(
    private router: Router,
    readonly tableUtils: TableUtils,
    readonly stateService: StateService,
    private readonly preferences: PreferencesService,
    readonly pluginService: PluginService,
    private readonly batchOperationService: BatchOperationService,
    searchInputService: SearchInputService,
    readonly stagingService: StagingService,
    readonly progressbar: ProgressbarService,
    readonly vodSearchService: VodSearchService,
    performanceService: FirebasePerformanceService,
    private readonly analyticsService: FirebaseAnalyticsService,
  ) {

    this.tableId = environment.tableInfoId['searchListViewTable'];
    this.performanceTrace =
      performanceService.startTrace(TraceName.SEARCH_RESULT_PAGE_READY);
    searchInputService.searchType$.next(SearchType.VOD);

    // Converts results to padded segments bound in the template
    this.results$ =
      this.vodSearchService.searchResponse$.pipe(map((response) => {
        // Do not display anything until we have results. A `null` value is
        // received when the chips have been cleared.
        if (response === null) return undefined;
        const isInitialFacetsResponse =
          Boolean(response.isInitialFacetsResponse);
        this.performanceTrace?.stopAfter(response.videoSegments.length);
        this.analyticsService.logSearchEvent('VoD search');
        const segments =
          this.vodSearchService.withPadding(response.videoSegments);
        return { segments, isInitialFacetsResponse };
      }));

    this.searchMode$ = this.vodSearchService.searchResponse$.pipe(
      map(response => response?.searchMode));

    // When search was done in SEGMENT mode force switch UI to Grid view.
    this.searchMode$.pipe(takeUntil(this.destroyed$)).subscribe(mode => {
      if (mode === SearchMode.SEGMENT) {
        this.vodSearchService.displayMode$.next(DisplayMode.GRID);
      }
    });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getFormattedContentType(asset: Original) {
    const contentType =
      asset.assetMetadata.jsonMetadata[MetadataField.CONTENT_TYPE] as unknown;
    if (contentType == null) return '';
    if (Array.isArray(contentType)) return contentType.join(', ');

    return String(contentType);
  }

  isSelected(segment: PaddedSegment) {
    return this.vodSearchService.selectedSegmentNames.has(segment.name);
  }

  getSelectionInfo(segments: PaddedSegment[], selectedNames: Set<string>) {
    return this.tableUtils.getSelectionInfo(
      segments,
      selectedNames,
      seg => this.canBeSelected(seg),
    );
  }

  /**
   * Toggles segment selection if shift is not pressed. Otherwise toggles a
   * block of items based on target asset and anchor asset.
   */
  toggleSelection(
    segment: PaddedSegment, allSegments: PaddedSegment[],
    shiftPressed = false) {
    const selectedNames = this.vodSearchService.selectedSegmentNames;

    const { itemsToSelect, itemsToUnSelect, anchorName } =
      this.tableUtils.processMultiSelect({
        items: allSegments,
        target: segment,
        selectedNames,
        shiftPressed,
        anchorName: this.selectionAnchorAssetName,
        canBeSelected: seg => this.canBeSelected(seg),
      });

    this.selectionAnchorAssetName = anchorName;

    for (const item of itemsToSelect) {
      selectedNames.add(item.name);
    }
    for (const item of itemsToUnSelect) {
      selectedNames.delete(item.name);
    }

    // Remove selection that can be caused by shift-clicking.
    document.getSelection()?.removeAllRanges();
  }

  /**
   * By default, clicking on the asset row opens asset details page. However
   * when shift key is pressed then this method kicks in and performs row
   * multi-select logic.
   */
  toggleSelectionOnShift(
    segment: PaddedSegment, allSegments: PaddedSegment[], event: MouseEvent) {
    if (!event.shiftKey) return;

    event.stopPropagation();
    this.toggleSelection(segment, allSegments, true);
  }

  trackSegment(index: number, segment: PaddedSegment) {
    return segment.name;
  }


  canBeSelected({ asset }: PaddedSegment) {
    return !asset.isDeleted;
  }

  /**
   * Sorts the provided rows based on the specified active column.
   *
   * @param sortParams - TableSort parameters.
   * @param sortParams.active - The column key name to sort by.
   * @param rows - The rows to be sorted.
   * @returns A new array containing the sorted rows.
   */
  onSort({ active: column }: TableSort, rows: PaddedSegment[]): PaddedSegment[] {
    return this.tableUtils.sortByField<PaddedSegment>(rows, column, true);
  }

  /**
   * Navigates to the asset details page.
   *
   * @param row The current row to open the video player
   * @param rows The list of rows with PaddedSegment type
   */
  routerTo(row: PaddedSegment & RowEvent, rows: PaddedSegment[]) {
    if (row.event.shiftKey) {
      this.toggleSelectionOnShift(row, rows, row.event);
      return;
    }
    const command = ['/asset', row.asset.name];
    const navigationExtras: UrlCreationOptions = {
      queryParams: {
        'type': 'search', 'initialIndex': this.vodSearchService.getAbsoluteIndex(row.index)
      },
      queryParamsHandling: 'merge'
    };
    // this.router.createUrlTree(, navigationExtras);
    this.router.navigate(command, navigationExtras);
  }


  isList(displayMode: DisplayMode) {
    return displayMode === DisplayMode.LIST;
  }

  select(segments: PaddedSegment[]) {
    this.vodSearchService.selectedSegmentNames.clear();
    for (const segment of segments) {
      this.vodSearchService.selectedSegmentNames.add(segment.name);
    }
  }

  async addClipsToBins(segments: PaddedSegment[]) {
    await this.batchOperationService.addClipsToBinsWithConfirmation(
        segments.map(s => s.asset));
  }

  async edit(segments: PaddedSegment[]) {
    this.stateService.currentPersistentTab$.next('staging');
    this.stagingService.edit(segments.map(s => s.asset));
  }

  exportOriginalAssets(segments: PaddedSegment[]) {
    this.batchOperationService.exportAssetsWithDialog(
        segments.map(s => s.asset));
  }

  async extendAssetsTtl(segments: PaddedSegment[]) {
    await this.batchOperationService.extendTtlWithDatePicker(
        segments.map(s => s.asset));
  }


  async deleteAssets(segments: PaddedSegment[]) {
    await this.batchOperationService.deleteAssetsWithConfirmation(
        segments.map(s => s.asset));
  }

  async purgeAssets(segments: PaddedSegment[]) {
    await this.batchOperationService.purgeAssetsWithConfirmation(
        segments.map(s => s.asset));
  }

  onPageChange({previousPageIndex, pageIndex, pageSize}: PageEvent) {
    // Abandon performance trace if the page has changed.
    // It is either completed at this point or will log incorrect results.
    this.performanceTrace?.abort();

    // Page size is changed
    if (pageSize !== this.vodSearchService.pageSize$.value) {
      this.vodSearchService.pageSize$.next(pageSize);
      // Re-trigger search from the first page.
      this.vodSearchService.pageChange$.next(0);
      this.analyticsService.logSearchEvent(
          'Page size changed', {number1: pageSize});
      this.storePageSize(pageSize);
      return;
    }

    this.vodSearchService.pageChange$.next(pageIndex);

    if (pageIndex > (previousPageIndex ?? 0)) {
      this.analyticsService.logSearchEvent(
          'Next search result page', {number1: pageIndex});
    } else {
      this.analyticsService.logSearchEvent(
          'Previous search result page', {number1: pageIndex});
    }
  }

  private storePageSize(pageSize: number) {
    this.preferences.save('search_list_page_size', String(pageSize));
  }
  }
