import {Injectable} from '@angular/core';
import {FormControl} from '@angular/forms';
import {DateTime} from 'luxon';
import {BehaviorSubject, combineLatest, EMPTY, firstValueFrom, Observable, of} from 'rxjs';
import {debounceTime, expand, map, reduce, startWith, switchMap, tap} from 'rxjs/operators';

import {FeatureFlagService} from 'feature_flag/feature_flag_service';
import {CompReelData, ExportStatus, Folder, PfrStateInfo} from 'models';

import {ErrorResponse, isErrorResponse, mapOnSuccess} from '../error_service/error_response';
import {ErrorService} from '../error_service/error_service';
import {AssetService, Clip, ClipOperationStatus} from '../services/asset_service';
import {Bin, BinService} from '../services/bin.service';
import {ClipApiService} from '../services/clip_api_service';
import {ApiCompReelState, ApiExportStatusExportState, ApiPFRStateInfoState} from '../services/ias_types';
import {MediaCacheService} from '../services/media_cache_service';
import {ProgressbarService} from '../services/progressbar_service';
import {SnackBarService} from '../services/snackbar_service';
import {TaskUsersService} from '../services/task_user_service';
import {TimezoneService} from '../services/timezone_service';
import {TransferService} from '../services/transfer_service';
import {UtilsService} from '../services/utils_service';

/** Column name for VoD and Live export monitors. */
export const EXPORT_COLUMNS = [
  'title',
  'fileName',
  'exportFolder',
  'clipBin',
  'user',
  'duration',
  'updateTime',
  'pfrStatus',
];

/** Response from a call to `listExportItems` */
export interface ExportsResponse {
  nextPageToken?: string;
  items: ExportItemResponse[];
}

/** Data for display and retry. */
export interface ExportItemResponse {
  clipBinTitle?: string,
  clipBinName?: string,
  fileName: string;
  exportFolder: string;
  title: string;
  updateTime: string;
  duration?: string | number;
  status: ClipOperationStatus;
  folderPath: string;
  name: string;
  errorMessage?: string;
  user?: string;
}

/** The export item types. */
export type ExportItem = Clip|Bin;

/** The export item's export information. */
export type ExportInfo = PfrStateInfo|ExportStatus|CompReelData;

/** Number of exports loaded on each call during the expand recursion. */
const PAGE_SIZE = 100;

/** Maximum number of exports loaded for a specific date. */
const MAX_COUNT_PER_DATE = 500;

type ExportState =
    ApiPFRStateInfoState|ApiExportStatusExportState|ApiCompReelState;

/** Export Monitor Service */
@Injectable({providedIn: 'root'})
export abstract class ExportMonitorService {
  /** List of export folder paths of the selected site. */
  exportFolderPaths$: Observable<string[]> = of([]);

  items = new BehaviorSubject<ExportItemResponse[]>([]);
  items$: Observable<ExportItemResponse[]> = this.items.asObservable();

  /** Form control for search input. */
  search = new FormControl<string>('');

  selectedDate$ =
      new BehaviorSubject<DateTime>(this.timezoneService.getTodayDate());

  readonly selectedDateLabel$: Observable<string>;

  readonly todayButtonDisabled$: Observable<boolean>;

  abstract getStateMap(item: ExportItem): Record<string, ExportInfo>|undefined;

  abstract getState(info: ExportInfo): ExportState;

  abstract listExportItems(
      userQuery: string, date: DateTime, pageSize: number,
      pageToken?: string): Observable<ExportsResponse|ErrorResponse>;

  abstract retryExport(
      siteId: string, folder: Folder, itemName: string,
      scratchFolderName?: string): Observable<ExportItem|ErrorResponse>;

  constructor(
      private readonly snackbar: SnackBarService,
      protected readonly assetService: AssetService,
      protected readonly binService: BinService,
      protected readonly clipApi: ClipApiService,
      protected readonly taskUserService: TaskUsersService,
      protected readonly errorService: ErrorService,
      protected readonly featureFlag: FeatureFlagService,
      protected readonly mediaCache: MediaCacheService,
      protected readonly transferService: TransferService,
      protected readonly utils: UtilsService,
      readonly progressbar: ProgressbarService,
      private readonly timezoneService: TimezoneService,
  ) {
    this.selectedDateLabel$ = this.selectedDate$.pipe(
        map(date => date.toLocaleString(DateTime.DATE_FULL, { locale: "en" })));

    this.todayButtonDisabled$ = this.selectedDate$.pipe(
        map(date => date.equals(this.timezoneService.getTodayDate())));

    this.exportFolderPaths$ = this.transferService.siteAndExportFolders$.pipe(
        map(({site, folders}) => {
          const folderPaths = folders.map(
              folder =>
                  this.mediaCache.getFolderPath(site.siteId, folder.folderId));
          return folderPaths;
        }));
  }

  today() {
    this.selectedDate$.next(this.timezoneService.getTodayDate());
  }

  async previousDay() {
    const currentDate = await firstValueFrom(this.selectedDate$);
    this.selectedDate$.next(currentDate.minus({days: 1}));
  }

  async nextDay() {
    const currentDate = await firstValueFrom(this.selectedDate$);
    this.selectedDate$.next(currentDate.plus({days: 1}));
  }

  /**
   * From the selected site, getting the export VoD clips/Live Clips/Comp-reels
   * by the cache or API. If the page token has not been cached, add the data to
   * the cache and record the total size.
   */
  loadItems() {
     return combineLatest([
          this.selectedDate$, this.exportFolderPaths$, this.searchValueChange$
        ])
            .pipe(
                tap(() => {
                  this.progressbar.show();
                }),
                switchMap(([date, exportFolderPaths, searchValue]) => {
                  if (!exportFolderPaths.length) return of([]);
                  const userQuery = searchValue ?? '';
                  const exportFolders = new Set(exportFolderPaths);
                  let count = 0;
                  return this.listExportItems(userQuery, date, PAGE_SIZE)
                      .pipe(
                          // Keep listing all exports across all pages.
                          expand(response => {
                            if (isErrorResponse(response)) return EMPTY;

                            count += response.items.length;

                            // Abort if too many exports were loaded
                            if (!response.nextPageToken ||
                                count >= MAX_COUNT_PER_DATE) {
                              return EMPTY;
                            }

                            return this.listExportItems(
                                userQuery, date, PAGE_SIZE,
                                response.nextPageToken);
                          }),
                          reduce(
                              (acc, response) => {
                                if (isErrorResponse(acc)) return acc;
                                if (isErrorResponse(response)) return response;
                                return [...acc, ...response.items];
                              },
                              [] as ExportItemResponse[] | ErrorResponse),
                          // Filter out exports that are not part of the current
                          // site's list of export folders.
                          mapOnSuccess(response => {
                            return response.filter(
                                item => exportFolders.has(item.folderPath));
                          }));
                }),
                map(response => {
                  if (isErrorResponse(response)) {
                    this.snackbar.error({
                      message: 'Failed to load export jobs.',
                      details: response.message,
                    });
                    return [];
                  }
                  return response;
                }),
                // Order all items of the selected date by update time.
                map(exports => {
                  return [...exports].sort(
                    (a, b) => b.updateTime.localeCompare(a.updateTime));
                }),
                tap((exports) => {
                  this.items.next(exports);
                  // Show a message if we reached the max number of exports.
                  if (exports.length >= MAX_COUNT_PER_DATE) {
                    this.snackbar.message(`A maximum of ${
                        exports
                            .length} exports are listed, even though there may be more.`);
                  }
                  this.progressbar.hide();
                }),
            );
  }

  /**
   * Clean up the pagination, search value, and table data.
   */
  reset() {
    this.search.setValue('');
    this.items.next([]);
  }

  // Check if two different timezone ISO strings are in the same date.
  protected isSameDate(date1: string | null, date2: string | null) {
    if (!date1 || !date2) {
      return false;
    }
    const d1 = this.timezoneService.parseFromIso(date1).startOf('day');
    const d2 = this.timezoneService.parseFromIso(date2).startOf('day');
    return d1.equals(d2);
  }

  private readonly searchValueChange$ =
      this.search.valueChanges.pipe(debounceTime(300), startWith(''));
}
