
<h2 mat-dialog-title>Share View</h2>
<mat-dialog-content>
  <p><b>Current View:</b> {{data.name}} </p>
  <p>Search for the email of users you want to share this view with.</p>

  <mat-form-field subscriptSizing="dynamic" appearance="outline">
    <input
      matInput
      type="text"
      [formControl]="searchEmail"
      [matAutocomplete]="auto"
      >
    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption (optionSelected)="addToShare($event)">
      @for (option of filteredOptions$ | async; track option) {
        <mat-option [value]="option">{{option}}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <mat-chip-set aria-label="Fish selection">
    @for (item of data.sharedWith; track $index) {
      <mat-chip [removable]="true" (removed)="removeShared($event)"> {{item}}
        <button matChipRemove [attr.aria-label]="'remove ' + item">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    }
  </mat-chip-set>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">CANCEL</button>
  <button mat-button [mat-dialog-close]="view">SHARE</button>
</mat-dialog-actions>
