/* eslint-disable @typescript-eslint/no-explicit-any */
import { CurrencyPipe, DatePipe, DecimalPipe, LowerCasePipe, PercentPipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { inject, Injector, Pipe, PipeTransform } from '@angular/core';

import { BestSeekUrlPipe } from '../pipes/best_seek_url_pipe';
import { BytesPipe } from '../pipes/bytes_pipe';
import { DurationOrTimePipe } from '../pipes/duration_or_time_pipe';
import { DurationPipe } from '../pipes/duration_pipe';
import { FolderStateToExportStatusPipe } from '../pipes/folder_state_to_export_status';
import { SpacedPipe } from '../pipes/spaced_pipe';
import { TimeAgoPipe } from '../pipes/time_ago_pipe';
import { TzDatePipe } from '../pipes/tzdate_pipe';


@Pipe({
  name: 'pipe',
  pure: true
})
export class UiTablePipe implements PipeTransform {
  injector = inject(Injector);

  transform(value: any, pipe: string, pipeArg?: string): typeof value {
    return this.injector.get(pipeMap[pipe]).transform(value, pipeArg);
  }
}

const pipeMap: any = {
  currency: CurrencyPipe,
  date: DatePipe,
  decimal: DecimalPipe,
  lowercase: LowerCasePipe,
  titlecase: TitleCasePipe,
  percent: PercentPipe,
  uppercase: UpperCasePipe,
  duration: DurationPipe,
  durationOrTime: DurationOrTimePipe,
  bestSeekUrl: BestSeekUrlPipe,
  bytes: BytesPipe,
  spaced: SpacedPipe,
  timeAgo: TimeAgoPipe,
  tzdate: TzDatePipe,
  exportStatus: FolderStateToExportStatusPipe,
};
