<h1 mat-dialog-title>Update View</h1>
<div mat-dialog-content>
  <p>
    This will update the view, are you sure you want to continue?
  </p>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel view update">
    Cancel
  </button>
  <button
      mat-button
      cdkFocusInitial
      color="primary"
      [mat-dialog-close]="true"
      (keyup.enter)="dialogRef.close(true)"
      mam-firebase-ga="Confirm view update">
    Update
  </button>
</div>
