<!-- Trigger change detection when on of these changes. -->
<ng-container *ngIf="{
  pendingApproval: stagingService.pendingApproval$ | async,
  isEditing: stagingService.isEditing$ | async,
  selectedAssetSet: stagingService.selectedAssetSet$ | async,
  activeAssetSet: stagingService.activeItemSet$ | async
} as tplState">

  <ng-container *ngIf="assets &&
    tplState.pendingApproval &&
    tplState.isEditing != null &&
    tplState.selectedAssetSet &&
    tplState.activeAssetSet &&
    getSelectionInfo(assets, tplState.selectedAssetSet) as selectionInfo">

    <div class="actions-search-bar-container">
      <div class="action-bar-container">
        <div class="video-actions">
          <!-- (De)Select all toggle. -->
          <mat-checkbox color="primary"
            [disabled]="!assets.length || loading || tplState.isEditing"
            (change)="selectionInfo.areAll
                        ? stagingService.select([])
                        : stagingService.select(selectionInfo.selectableItems)"
            [checked]="selectionInfo.areAll"
            [indeterminate]="selectionInfo.indeterminate"
            [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
            [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)"
            matTooltipPosition="above"
            mam-firebase-ga="Toggle selection for all assets in vod staging">
          </mat-checkbox>

          <!-- Actions for selected assets. -->
          <ng-container *ngIf="selectionInfo.areAny">
            <!-- Approve -->
            <button [disabled]="loading || tplState.isEditing"
              mat-icon-button
              class="approve-selected main__icon--size"
              aria-label="Approve selected assets"
              matTooltip="Approve selected assets"
              matTooltipPosition="above"
              mam-firebase-ga="Approve selected assets in vod staging"
              (click)="approve(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">check_circle</mat-icon>
            </button>
            <!-- Add to clip bin -->
            <button [disabled]="loading || tplState.isEditing" mat-icon-button class="add-selected-to-bins main__icon--size"
              aria-label="Add selected assets to clip bins" matTooltip="Add selected assets to clip bins"
              matTooltipPosition="above" mam-firebase-ga="Add selected assets to clip bins in vod staging"
              (click)="addClipsToBins(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">add_box</mat-icon>
            </button>
            <!-- Edit -->
            <button [disabled]="loading || tplState.isEditing"  mat-icon-button class="edit-selected main__icon--size"
              aria-label="Edit selected assets" matTooltip="Edit selected assets" matTooltipPosition="above"
              mam-firebase-ga="Bulk edit in vod staging" (click)="edit(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">edit</mat-icon>
            </button>
            <!-- Sync -->
            <button *featureFlagOn="'use-sync-metadata'" [disabled]="loading || tplState.isEditing"
              mat-icon-button class="sync-selected main__icon--size" aria-label="Sync selected assets"
              matTooltip="Sync selected assets" matTooltipPosition="above" mam-firebase-ga="Bulk sync in vod staging"
              (click)="syncMetadata(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">cloud_sync</mat-icon>
            </button>
            <!-- On-prem TTL Extension -->
            <button [disabled]="loading || tplState.isEditing" mat-icon-button class="extend-ttl-selected main__icon--size"
              aria-label="Extend TTL on selected assets" matTooltip="Extend TTL on selected assets"
              matTooltipPosition="above" mam-firebase-ga="Extend TTL on selected assets in vod staging"
              (click)="extendAssetsTtl(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">more_time</mat-icon>
            </button>
            <!-- On-prem purge -->
            <button [disabled]="loading || tplState.isEditing" mat-icon-button
              (click)="purgeAssets(selectionInfo.selectedItems)" class="purge-selected main__icon--size"
              aria-label="Delete on-prem files" matTooltip="Delete on-prem files" matTooltipPosition="above"
              mam-firebase-ga="Purge VoD staging selection">
              <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
            </button>
            <!-- Delete -->
            <button [disabled]="loading || tplState.isEditing" mat-icon-button class="delete-selected main__icon--size"
              aria-label="Delete selected assets" matTooltip="Delete selected assets" matTooltipPosition="above"
              mam-firebase-ga="Delete selected assets in vod staging"
              (click)="deleteAssets(selectionInfo.selectedItems)">
              <mat-icon class="material-icons-outlined">delete</mat-icon>
            </button>
          </ng-container>
        </div>

        <!-- Refresh -->
        <div class="video-actions">
          <button
            [disabled]="loading || tplState.isEditing"
            class="refresh-button"
            mat-icon-button
            aria-label="Refresh the list"
            matTooltip="Refresh the list"
            matTooltipPosition="above"
            mam-firebase-ga="Refresh table in vod staging"
            (click)="refreshTable()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>

      <!-- Search & Pagination -->
      <div class="search-bar-container">
        <mat-form-field
          appearance="outline"
          class="search-bar"
          subscriptSizing="dynamic"
          [class.disabled]="tplState.isEditing">
          <mat-icon matPrefix>
            search</mat-icon>
          <input
            type="text"
            [readonly]="tplState.isEditing"
            matInput
            [formControl]="search"
            placeholder="Search assets"
            aria-label="Search assets"
            autocomplete="off">
          <button
            [disabled]="tplState.isEditing"
            *ngIf="search.value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            mam-firebase-ga="Clear asset search query in vod staging"
            (click)="search.setValue('')">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-paginator
          [disabled]="loading || tplState.isEditing"
          [length]="totalCount"
          [pageSize]="pageSize"
          [pageIndex]="currentPageIndex"
          (page)="changePage($event)"
          [pageSizeOptions]="PAGE_SIZE_OPTIONS"
          [hidePageSize]="!featureService.featureOn('use-vod-staging-page-size-selector')"
          class="vod-staging-paginator main__mat-paginator--border">
        </mat-paginator>
      </div>
    </div>

    <mam-ui-table
      [cols]="cols"
      [data]="rows"
      [options]="{
        tableInfo: {
          name: 'vodStagingTable',
          id: tableId
        },
        multi: true,
        loading: this.loading || false,
        mainColIdx: 1
      }"
      [selectedRows]="tplState.selectedAssetSet"
      [activeRows]="tplState.activeAssetSet"
      (sortClick)="onSortChanged($event)"
      (rowClick)="onRowClick($event, tplState.activeAssetSet);"
      >
      <ng-template #headerTpl let-col>

        <ng-container *ngIf="col.headerTpl === 'sourceTpl'">
          <mam-sort-col-btn
            [rows]="rows"
            [key]="'source'"
            colName="Source"
            (mamSortByField)="onSortByField($event)">
              Source
          </mam-sort-col-btn>
        </ng-container>

        <ng-container *ngIf="col.headerTpl === 'statusTpl'">
          <mam-multiselect-table-header headerName="Status" [options]="statusFilterOptions"
            (toggle)="onStatusFilterChanged($event)">
          </mam-multiselect-table-header>
        </ng-container>

      </ng-template>

      <ng-template #cellTpl let-row let-col="col">

        <ng-container *ngIf="col.headerTpl === 'checkBoxTpl'">
          <mat-checkbox *ngIf="canBeSelected(row)" color="primary" [disabled]="loading || tplState.isEditing"
            (click)="$event.stopPropagation()" (change)="toggleSelection(row, tplState.selectedAssetSet)"
            [checked]="tplState.selectedAssetSet.has(row.name)"
            [aria-label]="tableUtils.checkboxLabel(row, tplState.selectedAssetSet)">
          </mat-checkbox>
        </ng-container>

        <ng-container *ngIf="col.cellTpl === 'nameTpl'">
          <div class="icon-with-text">
            <mam-asset-info-icon-button style="color: #3c4043;" [asset]="row" [icon]="getStatusIcon(row)"
              [showIconForExternalUsers]="true" [tooltip]="getStatusTooltip(row)">
            </mam-asset-info-icon-button>
            <span [title]="row.gcsLocationUrl">
              <span matTooltipClass="mobile-tooltip" matTooltip="{{assetService.getAssetTitle(row)}}"
                matTooltipPosition="below">{{assetService.getAssetTitle(row)}}</span>
            </span>
          </div>

        </ng-container>

        <!-- Duration -->
        <ng-container *ngIf="col.cellTpl === 'durationTpl'">
            {{row.duration | durationOrTime}}
        </ng-container>

        <!-- Status -->
        <ng-container *ngIf="col.cellTpl === 'statusTpl'">
          <div class="icon-with-text status-column-icon">
            <ng-container *ngIf="formatStatus(row) === 'Ready'">
              <mat-icon class="status-icon" matTooltip="Ready" matTooltipPosition="below">
                offline_pin
              </mat-icon>
              <span>Ready</span>
            </ng-container>
            <ng-container *ngIf="formatStatus(row) === 'Processing'">
              <mat-icon class="status-icon" matTooltip="Processing" matTooltipPosition="below">
                pending</mat-icon>
              <span>Processing</span>
            </ng-container>
            <ng-container *ngIf="formatStatus(row) !== 'Processing' && formatStatus(row) !== 'Ready'">
              <span>{{formatStatus(row)}}</span>
            </ng-container>
          </div>
        </ng-container>

        <!-- Schema -->
        <ng-container *ngIf="col.cellTpl === 'schemaTpl'">
          <span matTooltipClass="mobile-tooltip" matTooltip="{{assetService.getSchemaTitle(row) | async}}"
            matTooltipPosition="below">{{assetService.getSchemaTitle(row) | async}}</span>
        </ng-container>

         <!-- Storage -->
        <ng-container *ngIf="col.cellTpl === 'storageTpl'">
          <div class="location-status-wrapper">
            <mam-asset-location-status *ngIf="!row.isDeleted" [asset]="row">
            </mam-asset-location-status>
          </div>
        </ng-container>

      </ng-template>

    </mam-ui-table>

  </ng-container>

</ng-container>
