<h1 mat-dialog-title>Delete View</h1>
<div mat-dialog-content>
  <p>
    This will delete the view, are you sure you want to continue?
  </p>
</div>
<div mat-dialog-actions>
  <button
      mat-button
      mat-dialog-close
      mam-firebase-ga="Cancel view deletion">
    Cancel
  </button>
  <button
      mat-button
      cdkFocusInitial
      [mat-dialog-close]="true"
      (keyup.enter)="dialogRef.close(true)"
      class="danger"
      mam-firebase-ga="Confirm view deletion">
    Delete
  </button>
</div>
