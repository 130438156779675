<h2 mat-dialog-title>{{ view.id ? 'Rename view' : 'Add new view' }}</h2>
<mat-dialog-content>
	<mat-form-field subscriptSizing="dynamic" appearance="outline">
		<input cdkFocusInitial matInput type="text" [(ngModel)]="view.name" />
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false">CANCEL</button>
	<button mat-button [disabled]="!view.name" [mat-dialog-close]="view.name">Save</button>
</mat-dialog-actions>
