import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

/** View update dialog */
@Component({
  selector: 'mam-update-view-dialog',
  templateUrl: './update-view-dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateViewDialog {
  static readonly dialogOptions = {
    hasBackdrop: true,
  };

  constructor(
      readonly dialogRef: MatDialogRef<UpdateViewDialog>,
      @Inject(MAT_DIALOG_DATA) public data: {name: string}) {}
}
