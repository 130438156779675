import {ChangeDetectionStrategy, Component, OnDestroy} from '@angular/core';
import {ReplaySubject} from 'rxjs';

import {castExists} from 'asserts/asserts';

import {FeatureFlagService} from '../feature_flag/feature_flag_service';
import {DialogService} from '../services/dialog_service';
import {LocalUpload, LocalUploadStatus, LocalUploadsTrackingService} from '../services/local_uploads_tracking_service';

/**
 * Local Upload Table.
 */
@Component({
  selector: 'mam-local-upload-table',
  templateUrl: './local_upload_table.ng.html',
  styleUrls: ['./local_upload_table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalUploadTable implements OnDestroy {
  /** Data source for the table. */
  readonly localUploads$ = this.localUploadsTrackingService.localUploads$;
  readonly columnsToDisplay = ['fileName', 'user', 'size', 'startTime', 'duration', 'status', 'action'];
  readonly localUploadStatus = LocalUploadStatus;

  /** Flag to show local uploads' source column. */
  readonly showLocalUploadsSource = this.featureService.featureOn('show-user-information');

  constructor(
      private readonly localUploadsTrackingService: LocalUploadsTrackingService,
      private readonly dialogService: DialogService,
      private readonly featureService: FeatureFlagService
  ) {}

  onCancelClicked(upload: LocalUpload) {
    if (upload.status$.value !== LocalUploadStatus.UPLOADING) {
      this.localUploadsTrackingService.remove(upload);
      return;
    }

    this.dialogService
        .showConfirmation({
          title: 'Abort Upload',
          question: `Do you want to abort upload ${upload.fileName}?`,
        })
        .subscribe(result => {
          if (result) {
            // Confirmed cancellation.
            this.localUploadsTrackingService.remove(upload);
          }
        });
  }

  retryOrResumeOneFile(event: Event, upload: LocalUpload) {
    const target = event.target as HTMLInputElement;
    this.localUploadsTrackingService.retryOrResumeOneUpload(
      castExists(target.files)[0], upload);
    // No matter it succeeds or not, need to reset the input value.
    target.value = '';
  }

  onSortByField(rows: LocalUpload[]) {
    this.localUploads$.next(rows);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  trackByUpload(index: number, upload: LocalUpload) {
    return upload.fileName;
  }

  private readonly destroyed$ = new ReplaySubject<void>(1);
}
