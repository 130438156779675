<div class="actions-search-bar-container">
  <div class="search-bar-container">
    <mat-form-field appearance="outline" class="search-bar" subscriptSizing="dynamic">
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="text"
        matInput
        placeholder="Search transfers"
        aria-label="Search transfers"
        autocomplete="off"
        [formControl]="search"
      />
      <button
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        mam-firebase-ga="Clear transfer search query"
        (click)="search.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <mat-paginator
      [length]="totalSize"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      (page)="goToPage($event)"
      hidePageSize
      class="main__mat-paginator--border"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>

<mam-ui-table
  [cols]="cols"
  [data]="rows"
  [options]="{
    tableInfo: {
      name: 'transferTable',
      id: tableId
    },
    multi: true,
    loading: (transferService.processing$ | async) || false,
    mainColIdx: 0
  }"
  tableType="transfer"
  [expandedRows]="expandedRows"
  [activeSort]="activeSort"
  (sortClick)="onSort($event)"
  (rowClick)="onRowClick($event)"
>
  <ng-template #headerTpl let-col>
    <ng-container *ngIf="col.headerTpl === 'userTpl'">
      <mam-sort-col-btn [rows]="rows" [key]="'user'" colName="Source" (mamSortByField)="onSortByField($event)">
        Source
      </mam-sort-col-btn>
    </ng-container>

    <ng-container *ngIf="col.headerTpl === 'typeTpl'">
      <mam-multiselect-table-header
        headerName="Type"
        [options]="typeFilterOptions"
        [disableMenu]="(transferService.processing$ | async) || false"
        (toggle)="onTypeFilterChanged($event)"
      >
      </mam-multiselect-table-header>
    </ng-container>

    <ng-container *ngIf="col.headerTpl === 'statusTpl'">
      <mam-multiselect-table-header
        headerName="Status"
        [options]="statusFilterOptions"
        [disableMenu]="(transferService.processing$ | async) || false"
        (toggle)="onStatusFilterChanged($event)"
      >
      </mam-multiselect-table-header>
    </ng-container>
  </ng-template>

  <ng-template #cellTpl let-row let-col="col">
    <ng-container *ngIf="col.cellTpl === 'nameTpl'">
      <div class="main-column">
        <div class="icon-with-text">
          <mat-icon *ngIf="!isRowError(row)">insert_drive_file</mat-icon>
          <mat-icon matTooltip="Transfer failure" matTooltipClass="tooltip" *ngIf="isRowError(row)" class="error">
            error
          </mat-icon>
          <span [matTooltip]="col.name+': '+(row.name?.split('/')?.pop() | lowercase)" matTooltipClass="tooltip">
            {{row.name?.split('/')?.pop() | lowercase}}
          </span>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="col.cellTpl === 'typeTpl'"> {{isUploadTask(row) ? 'Upload' : 'Download'}} </ng-container>

    <ng-container *ngIf="col.cellTpl === 'intelligenceTpl'"> {{row.isMlEnabled ? 'Yes' : 'No'}} </ng-container>

    <ng-container *ngIf="col.cellTpl === 'statusTpl'">
      <ng-container [ngSwitch]="row.status">
        <ng-container *ngSwitchCase="TaskStatus.ACTIVE">
          <div class="icon-with-text status-column-icon">
            <mat-icon class="status-active-icon" matTooltip="Active" matTooltipClass="tooltip"> pending </mat-icon>
            <span class="status-active-text">Active</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="TaskStatus.FAILED">
          <button
            mat-flat-button
            [disabled]="isRowRetried(row) || (transferService.processing$ | async)"
            (click)="$event.stopPropagation(); onRetryClicked(row)"
            mam-firebase-ga="Retry transfer task"
            [mam-firebase-resource]="row.id"
            [ngClass]="'asset-table-button'"
          >
            Retry
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="TaskStatus.COMPLETED">
          <div class="icon-with-text status-column-icon">
            <mat-icon class="status-completed-icon" matTooltip="Completed" matTooltipClass="tooltip">
              check_circle
            </mat-icon>
            <span class="status-completed-text">Completed</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="col.cellTpl === 'expandTpl'">
      <button
        mat-icon-button
        mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} transfer table row"
        [mam-firebase-resource]="row.id"
        [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'"
      >
        <mat-icon> {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}} </mat-icon>
      </button>
    </ng-container>
  </ng-template>

  <ng-template #multiTpl let-row>
    <mam-transfer-details [task]="row.rawTask"> </mam-transfer-details>
  </ng-template>
</mam-ui-table>
