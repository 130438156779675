/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Directive, ElementRef, Input, NgZone, OnDestroy, Renderer2 } from '@angular/core';


@Directive({
    selector: '[mamTableStyle]'
  })
export class UiTableStyleDirective implements AfterViewInit, OnDestroy {
  @Input('mamTableStyle') styles?: any;
  private el: ElementRef;

  styleMap = new Map([
    ['width', 'width'],
    ['minWidth', 'min-width'],
    ['order', 'order'],
    ['textAlign', 'text-align'],
    ['fontWeight', 'font-weight'],
    ['whiteSpace', 'white-space'],
    ['textOverflow', 'text-overflow'],
    ['fontSize', 'font-size'],
    ['border', 'border'],
    ['backgroundColor', 'background-color'],
    ['color', 'color'],
    ['padding','padding']
  ]);

  constructor(private ngZone: NgZone, private renderer: Renderer2, private elRef: ElementRef) {
    this.el = this.elRef.nativeElement;
  }

  ngAfterViewInit() {
    this.setSytle();
  }

  ngOnDestroy() {
    this.styleMap.clear();
  }

  /**
   * Set Style
   */
  setSytle() {
    if(!this.styles) return;

    this.ngZone.runOutsideAngular(() => {
      Object.entries(this.styles).map(([key, value]) => {
        const style = this.styleMap.get(key);
        style && this.renderer.setStyle(this.el, style, `${value}`);
      });
    });
  }
}
