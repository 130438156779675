<ng-container *ngIf="assets">
  <div class="actions-search-bar-container">
    <div class="search-bar-container"
         [class.loading]="progressbar.visible$ | async">
      <mat-form-field appearance="outline"
                      class="search-bar"
                      subscriptSizing="dynamic">
        <mat-icon matPrefix>search</mat-icon>
        <input type="text"
               matInput
               placeholder="Search assets"
               aria-label="Search assets"
               autocomplete="off"
               [formControl]="search">
        <button *ngIf="search.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                mam-firebase-ga="Clear asset search query in asset deletion table"
                (click)="search.setValue('')">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <mat-paginator [disabled]="progressbar.visible$ | async"
                     [length]="pagination.totalCount"
                     [pageSize]="pageSize"
                     [pageIndex]="pagination.pageIndex"
                     (page)="changePage($event)"
                     class="main__mat-paginator--border"
                     hidePageSize>
      </mat-paginator>
    </div>
  </div>


  <mam-ui-table id="DeletedAssetsTable" *ngIf="assets?.length"
    class="deleted-list"
    [cols]="cols"
    [data]="assets"
    [options]="{
      tableInfo: {
        id: tableId,
        name: 'deletedAssetsTable'
      },
      multi: true,
      mainColIdx: 0
    }"
    [activeSort]="activeSort"
    (sortClick)="onSort($event)"
  >
    <ng-template #cellTpl let-row let-col="col">
      <!-- Title -->
      <ng-container *ngIf="col.cellTpl === 'titleTpl'">
        <div class="icon-with-text">
          <mam-asset-info-icon-button [asset]="row"
            icon="insert_drive_file"
            [showIconForExternalUsers]="true">
          </mam-asset-info-icon-button>
          <span matTooltipClass="mobile-tooltip"
                matTooltip="{{row.title}}"
                matTooltipPosition="below">
                {{row.title}}
          </span>
        </div>
      </ng-container>

      <!-- Undelete -->
      <ng-container *ngIf="col.cellTpl === 'undeleteTpl'">
        <button
          mat-flat-button aria-label="Undelete"
          mam-firebase-ga="Undelete the deleted asset"
          *ngIf="!undeletedAssetSet.has(row.name)"
          [disabled]="progressbar.visible$ | async"
          (click)="openUndeleteAssetDialog(row)"
          class="undelete-button asset-table-button">
          Undelete
        </button>
      </ng-container>

      <!-- Update time -->
      <ng-container *ngIf="col.cellTpl === 'updateTimeTpl'">
        {{row.updateTime | date:'MMM d, y, h:mm a'}}
      </ng-container>

      <!-- Duration -->
      <ng-container *ngIf="col.cellTpl === 'durationTpl'">
          {{row.duration | durationOrTime}}
      </ng-container>
    </ng-template>

    <ng-template #multiTpl let-row>
      <mam-transfer-details
        [task]="row.rawTask">
      </mam-transfer-details>
    </ng-template>

  </mam-ui-table>


  <div *ngIf="!assets?.length" class="empty-message">
    No deleted assets
  </div>

</ng-container>
