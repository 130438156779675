/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import * as CryptoJS from 'crypto-js';

import { environmentDetails } from './environment_cred';
import { Environment, environmentDefaults } from './environment_types';

const decrypted = CryptoJS.AES.decrypt(environmentDetails.encrypt, environmentDetails.enryptID);
const decrypt = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
const cred = JSON.parse(decrypt);

/** Makes real API calls */
export const environment: Environment = {
    ...environmentDefaults,
    tag: 'qa',
    projectId: cred.projectId,
    clientId:
        cred.qa_client,
    mamApi: {
        baseUrl:
            'https://staging-intelligentassetservice-googleapis.sandbox.google.com',
        parent:
            'projects/234973717435/locations/global',
        bucketRootUri:
        'gs://foxsports-ias-sandbox-archive01/'
    },
    pubsubApi: {
        baseUrl: 'https://pubsub.googleapis.com',
        topic: 'projects/foxsports-mediaarchive-sandbox/topics/ias_metadata_topic',
    },
    stackDriverErrorReportingApiKey: cred.qa_stack,
    firebaseConfig: {
        apiKey: cred.qa_fireapi,
        appId: '1:369705616743:web:393e7783831f7d3ad21bc2',
        projectId: cred.projectId,
    },
    maintenance: false,
    tableInfoId: {
      "adminUsersTable":"c07c5995-d9cb-4775-a3c4-0b4013b20ed3",
      "searchListViewTable":"2be41e13-d1b2-4485-abb7-330426f879f6",
      "deletedAssetsTable":"8c37d493-63ca-4c7e-92fc-5b8a0ac022ff",
      "exportMonitorTable":"2df1d2f4-d418-4f57-ab48-e68c6c6c2111",
      "liveStagingTable":"751b7e2b-1b4b-4494-b329-b43662669a9d",
      "transferTable":"a571ce7c-aac0-4279-9f22-c871c9caa081",
      "vodStagingErrorTable":"d1a22625-215e-4706-bce7-59923c7d5c71",
      "vodStagingTable":"283b7f19-b091-4bfe-9a29-4a84effac20d"
   },
    defaultSuggestions: cred.defaultSuggestions,
};
