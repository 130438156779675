import {Injectable} from '@angular/core';
import {DateTime} from 'luxon';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';

import {ExportStatus, Folder} from 'models';

import {ErrorResponse, mapOnSuccess} from '../error_service/error_response';
import {Clip} from '../services/asset_service';
import {ApiExportStatusExportState} from '../services/ias_types';

import {ExportItemResponse, ExportMonitorService, ExportsResponse} from './export_monitor_service';

/**
 * Live export monitor service.
 */
@Injectable({providedIn: 'root'})
export class ExportMonitorLiveService extends ExportMonitorService {
  getState(info: ExportStatus): ApiExportStatusExportState {
    return info.exportState;
  }

  getStateMap(item: Clip) {
    return item.exportInfo.stateMap;
  }

  listExportItems(
      userQuery: string, date: DateTime, pageSize: number,
      pageToken?: string): Observable<ExportsResponse|ErrorResponse> {
    return this.clipApi
        .listExportLiveClips(userQuery, pageSize, pageToken, date)
        .pipe(
            this.errorService.retryLong(),
            this.errorService.catchError(),
            mapOnSuccess(response => {
              const {assets, nextPageToken} = response;

              const items = assets.reduce<ExportItemResponse[]>((acc, clip) => {
                const {stateMap} = clip.exportInfo;
                const folderPaths = Object.keys(stateMap);

                folderPaths.forEach(fp => {
                  const info = stateMap[fp];

                  // Note: info.updateTime is in UTC.
                  // The info.updateTime might not be the selected date since
                  // it's the behavior of status map and the bug from BE
                  // (b/b/254074543).
                  if (this.isSameDate(info.updateTime, date.toISO())) {
                    acc.push({
                      fileName: this.utils.lastPart(info.outputFilePath) || '-',
                      exportFolder: this.utils.lastPart(fp),
                      title: clip.title,
                      updateTime: info.updateTime,
                      status: this.assetService.formatExportFolderStatus(
                          info.exportState),
                      folderPath: fp,
                      duration: clip.duration || 0,
                      name: clip.name,
                      clipBinName: clip.label ?? '',
                      errorMessage: info.errorDetails.message,
                    });
                  }
                });

                return acc;
              }, []);

              return {items, nextPageToken};
            }),
        )
        .pipe(
          mergeMap(
          originalResponse => {
            const response = (originalResponse as ExportsResponse);
            const originalItems = response.items;
            const nextPageToken = response.nextPageToken;

            if (!originalItems || originalItems.length == 0 ) return of (originalResponse);
            const completedItems = originalItems.map(
              originalItem => this.taskUserService
                .completeLiveExportWithUser(originalItem)
            );

            return combineLatest(completedItems).pipe(
              map(items => {
                return {items,nextPageToken} as ExportsResponse;
              })
            );
          }),
        shareReplay({bufferSize: 1, refCount: false})
      );
  }

  retryExport(siteId: string, folder: Folder, itemName: string):
      Observable<Clip|ErrorResponse> {
    return this.assetService.exportClip(itemName, folder.name);
  }
}
