import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, Output, Renderer2 } from "@angular/core";

@Directive({
  selector: "[resizable]"
})
export class ResizableDirective {
  startResinzing: number = 0;

  @Input() resizable:boolean = false;
  @Input() element!:HTMLTableCellElement;
  @Output() resizingEnd = new EventEmitter<number>();

  constructor(
    @Inject(DOCUMENT) private readonly documentRef: Document,
    @Inject(ElementRef)
    private readonly elementRef: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

/**
 * Allow the resize movement if the col.resizer is `TRUE`
 */
  @HostListener('touchstart', ['$event'])
  @HostListener('mousedown', ['$event'])
   mouseDown(event: MouseEvent | TouchEvent) {
    if(this.resizable) {
      this.startResinzing = event instanceof MouseEvent
      ? event.clientX
      : event.touches[0].clientX;
      event.preventDefault();
    }
  }

  /**
   * Stop the resizing movement
   */
 @HostListener('touchend', ['$event'])
 @HostListener('mouseup', ['$event'])
 @HostListener('body:mouseup', ['$event'])
  mouseUp(event: MouseEvent | TouchEvent) {
      if(this.resizable && this.startResinzing !== 0) {
        this.resizingEnd.emit(this.element.clientWidth);
      }
      this.startResinzing = 0;
			event.preventDefault();
  }


  @HostListener('touchmove', ['$event'])
  @HostListener('mousemove', ['$event'])
  @HostListener('body:mousemove', ['$event'])
  mouseMove(event: MouseEvent | TouchEvent) {
    if (this.startResinzing) {
      const mousePosition = event instanceof MouseEvent
      ? event.clientX
      : event.touches[0].clientX;

      this.setNewWidth(this.element, mousePosition);
    }
  }

  /**
   * @param element
   * Element to set the new width
   * @param initialPosition
   * Mouse/touch initial position
   * @description
   *Set new Width on title element
   *It retrieves the current mouse position, the right corner of the element, and the current element width.
   *Then, it calculates the difference (in pixels) between the corner of the element and the mouse position.
   */
  setNewWidth(element: HTMLTableCellElement, initialPosition: number) {
    const bounderingPosition = element.getBoundingClientRect().right;
    const currentWidth = element.clientWidth;
    const updatePixel = bounderingPosition - initialPosition;
    const width = currentWidth - updatePixel;
    const limitWidth = this.getLimitWidth(element);
		if(width > limitWidth) {
			this.renderer.setStyle(element, 'width', width + 'px');
		}
  }


  /**LimitWidth is necessary to bar-line doesn't disappear on resizing */
  /**
   *
   * @param element element to get min-width
   * @returns number of pixel
   */
  private getLimitWidth(element: HTMLTableCellElement): number {
    const styles = element.getAttribute('style')?.split(';').map(s => s.trim());
    const minWidthStyle = styles?.find((style) => style.includes('min-width'));
    const minWidth = minWidthStyle?.split(':')[1]?.trim().replace('px', '');
    const { clientWidth } = element.firstChild?.firstChild as HTMLElement;
    const limitWidth = minWidth ?? clientWidth;
    return +limitWidth;
  }
}
