<ng-container
  *ngIf="{
  displayMode: (vodSearchService.displayMode$ | async)!,
  results: results$ | async,
  searchMode: searchMode$ | async
} as tplState"
>
  <ng-container
    *ngIf="
      tplState.results &&
      tplState.searchMode &&
      getSelectionInfo(tplState.results.segments, vodSearchService.selectedSegmentNames) as selectionInfo"
  >
    <!-- List view header -->
    <div *ngIf="isList(tplState.displayMode)" class="search-bar-container">
      <div class="video-actions">
        <!-- Select/De-select all. -->
        <mat-checkbox
          color="primary"
          (change)="selectionInfo.areAll
                            ? select([])
                            : select(selectionInfo.selectableItems)"
          [checked]="selectionInfo.areAll"
          [indeterminate]="selectionInfo.indeterminate"
          [aria-label]="tableUtils.allCheckboxLabel(selectionInfo)"
          [matTooltip]="tableUtils.allCheckboxLabel(selectionInfo)"
          matTooltipPosition="above"
          mam-firebase-ga="Toggle selection for all segments in search results"
        >
        </mat-checkbox>

        <!-- Actions for selected assets. -->
        <ng-container *ngIf="selectionInfo.areAny">
          <!-- Add clips -->
          <button
            mat-icon-button
            (click)="addClipsToBins(selectionInfo.selectedItems)"
            class="add-selected-to-bins main__icon--size"
            aria-label="Add selected assets to clip bins"
            matTooltip="Add selected assets to clip bins"
            matTooltipPosition="above"
            mam-firebase-ga="Add selected assets to clip bins from search results"
          >
            <mat-icon class="material-icons-outlined">add_box </mat-icon>
          </button>

          <!-- Edit assets metadata -->
          <button
            mat-icon-button
            (click)="edit(selectionInfo.selectedItems)"
            class="edit-selected main__icon--size"
            aria-label="Edit selected assets"
            matTooltip="Edit selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Bulk assets edit in search results"
          >
            <mat-icon class="material-icons-outlined">edit</mat-icon>
          </button>

          <!-- Export to Folder -->
          <button
            mat-icon-button
            class="main__icon--size"
            (click)="exportOriginalAssets(selectionInfo.selectedItems)"
            aria-label="Export selected assets"
            matTooltip="Export selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Export selected full assets in search results"
          >
            <mat-icon>arrow_outward</mat-icon>
          </button>

          <!-- TTL Extension -->
          <button
            mat-icon-button
            (click)="extendAssetsTtl(selectionInfo.selectedItems)"
            class="extend-ttl-selected main__icon--size"
            aria-label="Extend TTL on selected assets"
            matTooltip="Extend TTL on selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Extend TTL on selected assets in search results"
          >
            <mat-icon class="material-icons-outlined">more_time</mat-icon>
          </button>

          <!-- Purge -->
          <button
            mat-icon-button
            (click)="purgeAssets(selectionInfo.selectedItems)"
            class="purge-selected main__icon--size"
            aria-label="Delete on-prem files"
            matTooltip="Delete on-prem files"
            matTooltipPosition="above"
            mam-firebase-ga="Purge search results selection"
          >
            <mat-icon class="material-icons-outlined">folder_delete</mat-icon>
          </button>

          <!-- Delete -->
          <button
            mat-icon-button
            (click)="deleteAssets(selectionInfo.selectedItems)"
            class="delete-selected main__icon--size"
            aria-label="Delete selected assets"
            matTooltip="Delete selected assets"
            matTooltipPosition="above"
            mam-firebase-ga="Delete selected assets from search results"
          >
            <mat-icon class="material-icons-outlined">delete</mat-icon>
          </button>
        </ng-container>
      </div>

      <!-- Paginator for List view -->
      <ng-container *ngTemplateOutlet="paginator; context: {showPageSelector: true}"> </ng-container>
    </div>

    <div class="search-list-view__table--scroll">
      <mam-ui-table
        [cols]="cols"
        [data]="tplState.results.segments"
        (rowClick)="routerTo($event, tplState.results.segments)"
        [selectedRows]="vodSearchService.selectedSegmentNames"
        (sortClick)="tplState.results.segments = onSort($event, tplState.results.segments)"
        [options]="{
                    tableInfo: {
                      name: 'searchListViewTable',
                      id: tableId
                      },
                    multi: true,
                    mainColIdx: 1,
                  }"
        tableType="full-video"
      >
        <!-- Cells -->
        <ng-template #cellTpl let-row let-col="col">
          <!-- Checkbox -->
          <ng-container *ngIf="col.headerTpl === 'checkBoxTpl'">
            <mat-checkbox
              *ngIf="canBeSelected(row)"
              color="primary"
              (click)="toggleSelection(row, tplState.results.segments, $event.shiftKey); $event.stopPropagation()"
              [checked]="isSelected(row)"
              [aria-label]="tableUtils.checkboxLabel(row, vodSearchService.selectedSegmentNames)"
            >
            </mat-checkbox>
          </ng-container>

          <!-- Title / File Name -->
          <ng-container *ngIf="col.cellTpl === 'nameTpl'">
            <div class="icon-with-text">
              <mam-asset-info-icon-button
                [asset]="row.asset"
                icon="insert_drive_file"
                [showIconForExternalUsers]="true"
              >
              </mam-asset-info-icon-button>
              <span matTooltip="{{row.asset?.title}}" matTooltipClass="mobile-tooltip" matTooltipPosition="below">
                {{row.asset?.title}}
              </span>
            </div>
          </ng-container>

          <!-- Duration -->
          <ng-container *ngIf="col.cellTpl === 'durationTpl'"> {{row.asset.duration | durationOrTime}} </ng-container>

          <!-- Content Type Column -->
          <ng-container *ngIf="col.cellTpl === 'contentTypeTpl'"> {{getFormattedContentType(row.asset)}} </ng-container>

          <!-- Event Time Column -->
          <ng-container *ngIf="col.cellTpl === 'eventTimeTpl'">
            {{row.asset.eventTime | tzdate:'MMM d, y, HH:mm'}}
          </ng-container>

          <!-- Last Modified Column -->
          <ng-container *ngIf="col.cellTpl === 'lastModifiedTpl'">
            {{row.asset.updateTime | tzdate:'MMM d, y, HH:mm'}}
          </ng-container>

          <!-- Location/Storage Column -->
          <ng-container *ngIf="col.cellTpl === 'storageTpl'">
            <mam-asset-location-status
              *ngIf="tplState.searchMode === SearchMode.VIDEO &&
             !row.asset.isDeleted"
              [asset]="row.asset"
            >
            </mam-asset-location-status>
          </ng-container>

          <!-- Action Column -->
          <ng-container *ngIf="col.cellTpl === 'actionTpl'">
            <mam-asset-extra-actions
              *ngIf="!row.asset.isDeleted"
              [asset]="row.asset"
              [paddedSegment]="row"
              [enableFullMenu]="tplState.searchMode === SearchMode.VIDEO"
            >
            </mam-asset-extra-actions>
          </ng-container>

        </ng-template>
      </mam-ui-table>
    </div>

        <!-- Paginator Template -->
        <ng-template #paginator let-showPageSelector="showPageSelector">
            <mat-paginator
                class="main__mat-paginator--border"
                [ngClass]="['mat-paginator', (selectionInfo.indeterminate || selectionInfo.areAll) ? 'hide-paginator' : '']"
                [length]="vodSearchService.pagination.totalCount"
                [pageSize]="vodSearchService.pagination.pageSize"
                [pageIndex]="vodSearchService.pagination.pageIndex"
                (page)="onPageChange($event)"
                [pageSizeOptions]="PAGE_SIZE_OPTIONS"
                [hidePageSize]="!showPageSelector"
            ></mat-paginator>
        </ng-template>
    </ng-container>
</ng-container>
