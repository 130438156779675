<ng-container
  *ngIf="transferService.transferSelectedSite$ | async as transferSelectedSite">
  <button
    mat-stroked-button
    class="site-select"
    mam-firebase-ga="Open site picker"
    [mam-firebase-resource]="transferSelectedSite.name"
    [matMenuTriggerFor]="sitesMenu">
    <mat-icon class="exports-domain-icon">domain</mat-icon>
    <span>{{transferSelectedSite.siteId | uppercase}}</span>
    <mat-icon iconPositionEnd class="arrow">arrow_drop_down</mat-icon>
  </button>
  <mat-menu #sitesMenu="matMenu">
    <button *ngFor="let site of selectableSites$ | async" mat-menu-item
      (click)="transferService.selectTransferSite(site)" mam-firebase-ga="Select a site"
      [mam-firebase-resource]="site.name">
      <mat-icon>domain</mat-icon>
      <span>{{site.siteId | uppercase}}</span>
    </button>
  </mat-menu>
</ng-container>

<div class="actions-search-bar-container">
  <div class="search-bar-container"
       [class.loading]="exportService.progressbar.visible$ | async">
    <mat-form-field appearance="outline"
      class="search-bar"
      subscriptSizing="dynamic"
    >
      <mat-icon matPrefix>search</mat-icon>
      <input type="text"
         matInput
         placeholder="Search by title"
         aria-label="Search by title"
         autocomplete="off"
         [formControl]="exportService.search">

      <button mat-button
        *ngIf="exportService.search.value"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        [mam-firebase-ga]="'Clear {{exportType}} export query'"
        (click)="exportService.search.setValue('')">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div class="calendar-navigation">
      <button mat-flat-button class="today"
        [disabled]="exportService.todayButtonDisabled$ | async"
        [mam-firebase-ga]="'Go to today in {{exportType}} export monitor'"
        aria-label="Today"
        (click)="exportService.today()">
        Today
      </button>
      <button class="previous"
        matTooltip="Previous day"
        matTooltipPosition="above"
        mat-icon-button
        [mam-firebase-ga]="'Go to previous day in {{exportType}} export monitor'"
        aria-label="Previous day"
        (click)="exportService.previousDay()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button class="next"
        matTooltip="Next day"
        matTooltipPosition="above"
        mat-icon-button
        [mam-firebase-ga]="'Go to next day in {{exportType}} export monitor'"
        aria-label="Next day"
        [disabled]="exportService.todayButtonDisabled$ | async"
        (click)="exportService.nextDay()">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <span class="date-label">{{exportService.selectedDateLabel$ | async}}</span>
    </div>
  </div>
</div>


<mam-ui-table id="ExportMonitorTable" *ngIf="exportService.items$ | async as items"
  [cols]="cols"
  [data]="items"
  [options]="{
    tableInfo: {
      name: 'exportMonitorTable',
      id: tableId
      },
    multi: true,
    mainColIdx: 0
  }"
  tableType="site-selector"
  [activeSort]="activeSort"
  (sortClick)="onSort($event)"
  (rowClick)="onRowClick($event)"
>
  <ng-template #cellTpl let-row let-col="col">
    <!-- Title -->
    <ng-container *ngIf="col.cellTpl === 'titleTpl'">
      <div class="main-column">
        <div class="icon-with-text">
          <mat-icon *ngIf="!isRowError(row.status)">insert_drive_file</mat-icon>
          <mat-icon *ngIf="isRowError(row.status)"
            class="error"
            [matTooltip]="row.errorMessage"
            matTooltipClass="close-from-top">
            error
          </mat-icon>
          <span matTooltip="{{row.name}}"
            matTooltipPosition="below"
            matTooltipClass="mobile-tooltip">
            {{row.title}}
          </span>
        </div>
      </div>
    </ng-container>

    <!-- Clip bin -->
    <ng-container *ngIf="col.cellTpl === 'clipBinTpl'">
      <mam-export-monitor-clipbin-cell [clipBinName]="row.clipBinName"></mam-export-monitor-clipbin-cell>
    </ng-container>

    <!-- Duration -->
    <ng-container *ngIf="col.cellTpl === 'durationTpl'">
        {{ displayDuration ? (row.duration | durationOrTime) : '--'}}
    </ng-container>

    <!-- Status PFR -->
    <ng-container *ngIf="col.cellTpl === 'statusTpl'">
      <ng-container *ngIf="!isRowError(row.status)">
        <div class="icon-with-text status-column-icon">
          <ng-container *ngIf="row.status === 'Completed'">
            <mat-icon
              class="icon-completed"
              matTooltip="Completed"
              matTooltipPosition="below">
              check_circle
            </mat-icon>
            <span
              matTooltip="{{row.exportFolder}}"
              matTooltipPosition="below"
              matTooltipClass="mobile-tooltip">
              {{ row.status }}
            </span>
          </ng-container>
          <ng-container *ngIf="row.status === 'Pending'">
            <mat-icon
              class="icon-pending"
              matTooltip="Pending"
              matTooltipPosition="below">
              pending
            </mat-icon>
            <span>{{ row.status }}</span>
          </ng-container>
          <ng-container *ngIf="row.status === 'Downloading'">
            <mat-icon
              class="icon-pending"
              matTooltip="Downloading"
              matTooltipPosition="below">
              downloading
            </mat-icon>
            <span>{{ row.status }}</span>
          </ng-container>

          <ng-container
            *ngIf="row.status !== 'Completed' && row.status !== 'Pending' && row.status !== 'Downloading'">
            {{ row.status }}
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="isRowError(row.status)">
        <button mat-flat-button
                [disabled]="isRowRetried(row)"
                (click)="retryOnClick(row); $event.stopPropagation();"
                mam-firebase-ga="Retry"
                [mam-firebase-resource]="row.name"
                [matTooltip]="row.errorMessage"
                [ngClass]="'asset-table-button'">
                Retry
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="col.cellTpl === 'expandTpl'">
      <button
        mat-icon-button
        mam-firebase-ga="{{isRowExpanded(row) ? 'Expand' : 'Collapse'}} transfer table row"
        [mam-firebase-resource]="row.id"
        [attr.aria-label]="isRowExpanded(row) ? 'Collapse row' : 'Expand row'">
        <mat-icon>
          {{isRowExpanded(row) ? 'expand_less' : 'expand_more'}}
        </mat-icon>
      </button>
    </ng-container>

  </ng-template>

  <ng-template #multiTpl let-row>
    <mam-transfer-details
      [task]="row.rawTask">
    </mam-transfer-details>
  </ng-template>

</mam-ui-table>


